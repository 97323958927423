import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonButton from "../../../components/button/common-button/commonButton";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isLoggedInUserAdmin, isLoggedInUserVendor, isNull, isValidEmail } from "../../../utils/Utils";
import { loginEmployeeRequest, displaySideBarAct } from '../../../redux/user/user.action'
import { useDispatch, useSelector } from 'react-redux'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import NavBarComponent from "../../landing/nav-bar/navBar";
import PasswordInputFull from "../../../components/input/password-input-full/passwordInputFull";

const EmployeeLogin = props => {
    const [isLoading, setLoading] = useState(false)
    const [loginCode, setLoginCode] = useState('')
    const [password, setPassword] = useState('')
    let navigate = useNavigate();
    const { user, isLogin, loginErrorData } = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login Successful')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, JSON.stringify(user.data.authToken))
            dispatch(displaySideBarAct(false))
            navigate(ROUTES.employeeSelectLocation, { replace: true })
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleLoginClick = () => {
        if (isEmpty(loginCode)) {
            displayErrorToast('Login code cannot be blank')
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank')
        } else {
            const data = {
                employeeLoginCode: loginCode,
                password: password
            }
            setLoading(true)
            dispatch(loginEmployeeRequest(data))
        }
    }

    return (
        <>
            <NavBarComponent navigate={navigate}
                showHome={true}
                businessLoginLabel='BUSINESS LOGIN'
                showEmployeeLogin={false}
                showBusinessLogin={true}
                isWhiteBackground={true}
                showBusinessRegistration={true}
                showForCustomer={true} />
            <CommonScreenContentCenter>
                <CommonFormBorder>
                    <div className="cl-logo-container">
                        <img src={require('../../../assets/logo_gomint2.png')} className="img" />
                    </div>
                    <div className="cl-welcome-label">Welcome!</div>
                    <div className="cl-sub-label">Login with your registered employee credentials</div>
                    <CommonInputFloatFull
                        type='text'
                        id='loginCode'
                        onChange={e => setLoginCode(e.target.value)}
                        placeholder='Login Code'
                        value={loginCode} />
                    <PasswordInputFull
                        id='password'
                        onChange={e => setPassword(e.target.value)}
                        placeholder='Password'
                        value={password} />
                    <div className="vl-button-container">
                        <CommonButton label='Login'
                            onClick={() => { handleLoginClick() }} />
                    </div>

                </CommonFormBorder>
            </CommonScreenContentCenter >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeLogin