import { UserActionTypes } from './user.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    user: null,
    isLogin: false,
    loginErrorData: null,
    registerSuccess: null,
    registerError: null,
    registerReqStatus: '',
    subscriptionListSuccess: null,
    subscriptionListError: null,
    forgotPasswordSuccess: null,
    forgotPasswordError: null,
    forgotPasswordReqStatus: '',
    changePasswordSuccess: null,
    changePasswordError: null,
    changePasswordReqStatus: '',
    countryListSuccess: null,
    countryListError: null,
    stateListSuccess: null,
    stateListError: null,
    cityListSuccess: null,
    cityListError: null,
    displaySideBar: false,
    commonError: null,
    operatorRegistrationSuccess: null,
    operatorRegistrationError: null,
    operatorRegistrationReqStatus: '',
    operatorListSuccess: null,
    operatorListError: null,
    operatorDetailSuccess: null,
    operatorDetailError: null,
    updateOperatorLocationSuccess: null,
    updateOperatorLocationError: null,
    updateOperatorLocationReqStatus: '',
    updateNotificationTokenSuccess: null,
    updateNotificationTokenError: null,
    updateNotificationTokenReqStatus: '',
    selectedLocation: null,
    employeeSelectedBusiness: null,
    employeeSelectedLocation: null
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.LOGIN_USER_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.LOGIN_EMPLOYEE_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.LOGIN_EMPLOYEE_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.CUSTOMER_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.CUSTOMER_LOGIN_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                registerSuccess: action.payload,
                registerError: null,
                registerReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.REGISTER_REQUEST_ERROR:
            return {
                ...state,
                registerSuccess: null,
                registerError: action.payload,
                registerReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.REGISTER_REQUEST_STATUS:
            return {
                ...state,
                registerReqStatus: ''
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS:
            return {
                ...state,
                updateOperatorLocationSuccess: action.payload,
                updateOperatorLocationError: null,
                updateOperatorLocationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR:
            return {
                ...state,
                updateOperatorLocationSuccess: null,
                updateOperatorLocationError: action.payload,
                updateOperatorLocationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS:
            return {
                ...state,
                updateOperatorLocationReqStatus: ''
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                subscriptionListSuccess: action.payload,
                subscriptionListError: null
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR:
            return {
                ...state,
                subscriptionListSuccess: null,
                subscriptionListError: action.payload
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPasswordSuccess: action.payload,
                forgotPasswordError: null,
                forgotPasswordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                forgotPasswordSuccess: null,
                forgotPasswordError: action.payload,
                forgotPasswordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                forgotPasswordReqStatus: ''
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: action.payload,
                changePasswordError: null,
                changePasswordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                changePasswordSuccess: null,
                changePasswordError: action.payload,
                changePasswordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                changePasswordReqStatus: ''
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_SUCCESS:
            return {
                ...state,
                operatorRegistrationSuccess: action.payload,
                operatorRegistrationError: null,
                operatorRegistrationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_ERROR:
            return {
                ...state,
                operatorRegistrationSuccess: null,
                operatorRegistrationError: action.payload,
                operatorRegistrationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTRATION_REQUEST_STATUS:
            return {
                ...state,
                operatorRegistrationReqStatus: ''
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                operatorListSuccess: action.payload,
                operatorListError: null
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_ERROR:
            return {
                ...state,
                operatorListSuccess: null,
                operatorListError: action.payload
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                operatorDetailSuccess: action.payload,
                operatorDetailError: null
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                operatorDetailSuccess: null,
                operatorDetailError: action.payload
            }
        case UserActionTypes.LOGOUT:
            return INITIAL_STATE
        case UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                countryListSuccess: action.payload,
                countryListError: null
            }
        case UserActionTypes.COUNTRY_LIST_REQUEST_ERROR:
            return {
                ...state,
                countryListSuccess: null,
                countryListError: action.payload
            }
        case UserActionTypes.STATE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                stateListSuccess: action.payload,
                stateListError: null
            }
        case UserActionTypes.STATE_LIST_REQUEST_ERROR:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: action.payload
            }
        case UserActionTypes.CITY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                cityListSuccess: action.payload,
                cityListError: null
            }
        case UserActionTypes.CITY_LIST_REQUEST_ERROR:
            return {
                ...state,
                cityListSuccess: null,
                cityListError: action.payload
            }
        case UserActionTypes.UPDATE_USER_NOTIFICATION_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                updateNotificationTokenSuccess: action.payload,
                updateNotificationTokenError: null,
                updateNotificationTokenReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_USER_NOTIFICATION_TOKEN_REQUEST_ERROR:
            return {
                ...state,
                updateNotificationTokenSuccess: null,
                updateNotificationTokenError: action.payload,
                updateNotificationTokenReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_USER_NOTIFICATION_TOKEN_REQUEST_STATUS:
            return {
                ...state,
                updateNotificationTokenReqStatus: ''
            }
        case UserActionTypes.RESET_STATE_CITY:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: null,
                cityListSuccess: null,
                cityListError: null,
                countryListSuccess: null,
                countryListError: null
            }
        case UserActionTypes.DISPLAY_SIDE_BAR:
            return {
                ...state,
                displaySideBar: action.payload
            }
        case UserActionTypes.EMPLOYEE_BUSINESS_LOCATION_SELECTION:
            return {
                ...state,
                employeeSelectedBusiness: action.payload.businessId,
                employeeSelectedLocation: action.payload.locationId,
                displaySideBar: true
            }
        case UserActionTypes.COMMON_ERROR:
            return {
                ...state,
                commonError: action.errData
            }
        case UserActionTypes.UPDATE_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: action.payload
            }
        default:
            return state
    }
}

export default userReducer