import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addCustomerCashbackAPI, customerCashbackListAPI, customerCashbackRecordListAPI
} from '../api-requests/customerCashback';
import {
    addCustomerCashbackRequestError, addCustomerCashbackRequestSuccess, customerCashbackListRequestError,
    customerCashbackListRequestSuccess, customerCashbackRecordListRequestError, customerCashbackRecordListRequestSuccess
} from './customerCashback.action';
import { CustomerCashbackActionTypes } from './customerCashback.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleCustomerCashbackRecordListReq({ payload }) {
    try {
        const response = yield call(customerCashbackRecordListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerCashbackRecordListRequestSuccess(decryptedData))
            } else {
                yield put(customerCashbackRecordListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerCashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerCashbackRecordListRequestError(error.response.data))
        }
    }
}

export function* customerCashbackRecordListReq() {
    yield takeLatest(CustomerCashbackActionTypes.CUSTOMER_CASHBACK_RECORD_LIST_REQUEST, handleCustomerCashbackRecordListReq)
}

function* handleCustomerCashbackListReq({ payload }) {
    try {
        const response = yield call(customerCashbackListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerCashbackListRequestSuccess(decryptedData))
            } else {
                yield put(customerCashbackListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerCashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerCashbackListRequestError(error.response.data))
        }
    }
}

export function* customerCashbackListReq() {
    yield takeLatest(CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST, handleCustomerCashbackListReq)
}

function* handleAddCustomerCashbackReq({ payload }) {
    try {
        const response = yield call(addCustomerCashbackAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addCustomerCashbackRequestSuccess(decryptedData))
            } else {
                yield put(addCustomerCashbackRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerCashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addCustomerCashbackRequestError(error.response.data))
        }
    }
}

export function* addCustomerCashbackReq() {
    yield takeLatest(CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST, handleAddCustomerCashbackReq)
}