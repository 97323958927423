export const EmployeeActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    ADD_EMPLOYEE_REQUEST: 'ADD_EMPLOYEE_REQUEST',
    ADD_EMPLOYEE_REQUEST_SUCCESS: 'ADD_EMPLOYEE_REQUEST_SUCCESS',
    ADD_EMPLOYEE_REQUEST_ERROR: 'ADD_EMPLOYEE_REQUEST_ERROR',
    ADD_EMPLOYEE_REQUEST_STATUS: 'ADD_EMPLOYEE_REQUEST_STATUS',

    EMPLOYEE_LOGIN_CODE_CHECK_REQUEST: 'EMPLOYEE_LOGIN_CODE_CHECK_REQUEST',
    EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_SUCCESS: 'EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_SUCCESS',
    EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_ERROR: 'EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_ERROR',

    EMPLOYEE_LIST_REQUEST: 'EMPLOYEE_LIST_REQUEST',
    EMPLOYEE_LIST_REQUEST_SUCCESS: 'EMPLOYEE_LIST_REQUEST_SUCCESS',
    EMPLOYEE_LIST_REQUEST_ERROR: 'EMPLOYEE_LIST_REQUEST_ERROR',

    EMPLOYEE_DETAIL_REQUEST: 'EMPLOYEE_DETAIL_REQUEST',
    EMPLOYEE_DETAIL_REQUEST_SUCCESS: 'EMPLOYEE_DETAIL_REQUEST_SUCCESS',
    EMPLOYEE_DETAIL_REQUEST_ERROR: 'EMPLOYEE_DETAIL_REQUEST_ERROR',

    REMOVE_EMPLOYEE_REQUEST: 'REMOVE_EMPLOYEE_REQUEST',
    REMOVE_EMPLOYEE_REQUEST_SUCCESS: 'REMOVE_EMPLOYEE_REQUEST_SUCCESS',
    REMOVE_EMPLOYEE_REQUEST_ERROR: 'REMOVE_EMPLOYEE_REQUEST_ERROR',
    REMOVE_EMPLOYEE_REQUEST_STATUS: 'REMOVE_EMPLOYEE_REQUEST_STATUS',

    CLEAR_EMPLOYEE_LOGIN_CODE_CHECK: 'CLEAR_EMPLOYEE_LOGIN_CODE_CHECK',

    UPDATE_EMPLOYEE_DETAIL_REQUEST: 'UPDATE_EMPLOYEE_DETAIL_REQUEST',
    UPDATE_EMPLOYEE_DETAIL_REQUEST_SUCCESS: 'UPDATE_EMPLOYEE_DETAIL_REQUEST_SUCCESS',
    UPDATE_EMPLOYEE_DETAIL_REQUEST_ERROR: 'UPDATE_EMPLOYEE_DETAIL_REQUEST_ERROR',
    UPDATE_EMPLOYEE_DETAIL_REQUEST_STATUS: 'UPDATE_EMPLOYEE_DETAIL_REQUEST_STATUS'
}
