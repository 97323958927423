import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from "../../navigation-bar/navigationBar";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail } from "../../../utils/Utils";
import {
    registerRequest, countryListRequest, stateListRequest, cityListRequest,
    subscriptionListRequest, registerRequestStatus
} from '../../../redux/user/user.action'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import Select from 'react-select'
import NavBarComponent from "../../landing/nav-bar/navBar";

const VendorCreateAccount = props => {
    const [isLoading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    let navigate = useNavigate();
    const { registerSuccess, registerError, registerReqStatus, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, subscriptionListSuccess, subscriptionListError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [mobile, setMobile] = useState()

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(subscriptionListRequest())
        dispatch(countryListRequest())
    }, [])

    useEffect(() => {
        if (!isNull(subscriptionListSuccess)) {
            setLoading(false)
        } else if (!isNull(subscriptionListError)) {
            setLoading(false)
        }
    }, [subscriptionListSuccess, subscriptionListError])

    useEffect(() => {
        if (registerReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(registerSuccess.message)
            clearFields()
            setTimeout(() => {
                navigate(-1)
            }, 800)
        } else if (registerReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(registerError.message)
        }
        if (registerReqStatus?.length) {
            dispatch(registerRequestStatus(''))
        }
    }, [registerSuccess, registerError, registerReqStatus])

    const clearFields = () => {
        setEmail('')
        setPassword('')
        setBusinessName('')
        setFirstName('')
        setLastName('')
        setMobile()
    }

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const handleRegisterClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid email id')
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank')
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm password cannot be blank')
        } else if (password !== confirmPassword) {
            displayErrorToast('Password does not match')
        } else if (isNull(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else {
            setLoading(true)
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                email: email,
                password: password,
                firstName: firstName,
                userType: APP_CONSTANTS.USER_ROLE_TYPE.VENDOR,
                subscriptionId: subscriptionListSuccess.data[0].subscriptionId,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                mobile: parseInt(mobileWithPlusSymbol)
            }

            dispatch(registerRequest(data))
        }
    }

    return (
        <>
            <NavBarComponent navigate={navigate}
                showHome={true}
                businessLoginLabel='BUSINESS LOGIN'
                showForCustomer={true}
                showBusinessLogin={true}
                showEmployeeLogin={true}
                isWhiteBackground={true} />
            <CommonScreenContentCenter>
                <CommonFormBorder>
                    <div className="cl-logo-container">
                        <img src={require('../../../assets/logo_gomint2.png')} className="img" />
                    </div>
                    <div className="cl-sub-label">Enter below details to register as vendor</div>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            value={firstName}
                        />
                        <CommonInputFloatHalf
                            type='text'
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            value={lastName}
                        />
                    </CommonInputRow>
                    {/* <CommonInputFloatFull
                        type='text'
                        id='businessName'
                        onChange={e => setBusinessName(e.target.value)}
                        placeholder='Business Name'
                        value={businessName} /> */}
                    <CommonInputFloatFull
                        type='text'
                        id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Email Id'
                        value={email} />
                    <div className="cl-input">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='password'
                            id='password'
                            onChange={e => setPassword(e.target.value)}
                            placeholder='Password'
                            value={password} />
                        <CommonInputFloatHalf
                            type='text'
                            id='confirmpassword'
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder='Confirm Password'
                            value={confirmPassword} />
                    </CommonInputRow>
                    {/* {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow> */}

                    <ButtonRow>
                        <CommonButton label='Register'
                            onClick={() => { handleRegisterClick() }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContentCenter >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VendorCreateAccount