import { CashbackActionTypes } from './cashback.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addCashbackSuccess: null,
    addCashbackError: null,
    addCashbackReqStatus: '',
    cashbackListSuccess: null,
    cashbackListError: null,
    cashbackUpdatePaymentSuccess: null,
    cashbackUpdatePaymentError: null,
    cashbackUpdatePaymentReqStatus: '',
    vendorCashbackListSuccess: null,
    vendorCashbackListError: null,
    vendorCashbackDetailSuccess: null,
    vendorCashbackDetailError: null
}

const cashbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CashbackActionTypes.ADD_CASHBACK_REQUEST_SUCCESS:
            return {
                ...state,
                addCashbackSuccess: action.payload,
                addCashbackError: null,
                addCashbackReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CashbackActionTypes.ADD_CASHBACK_REQUEST_ERROR:
            return {
                ...state,
                addCashbackSuccess: null,
                addCashbackError: action.payload,
                addCashbackReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CashbackActionTypes.ADD_CASHBACK_REQUEST_STATUS:
            return {
                ...state,
                addCashbackReqStatus: ''
            }
        case CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                cashbackListSuccess: action.payload,
                cashbackListError: null
            }
        case CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST_ERROR:
            return {
                ...state,
                cashbackListSuccess: null,
                cashbackListError: action.payload
            }
        case CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                cashbackUpdatePaymentSuccess: action.payload,
                cashbackUpdatePaymentError: null,
                cashbackUpdatePaymentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                cashbackUpdatePaymentSuccess: null,
                cashbackUpdatePaymentError: action.payload,
                cashbackUpdatePaymentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                cashbackUpdatePaymentReqStatus: ''
            }
        case CashbackActionTypes.VENDOR_CASHBACK_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                vendorCashbackListSuccess: action.payload,
                vendorCashbackListError: null
            }
        case CashbackActionTypes.VENDOR_CASHBACK_LIST_REQUEST_ERROR:
            return {
                ...state,
                vendorCashbackListSuccess: null,
                vendorCashbackListError: action.payload
            }
        case CashbackActionTypes.VENDOR_CASHBACK_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                vendorCashbackDetailSuccess: action.payload,
                vendorCashbackDetailError: null
            }
        case CashbackActionTypes.VENDOR_CASHBACK_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                vendorCashbackDetailSuccess: null,
                vendorCashbackDetailError: action.payload
            }
        case CashbackActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default cashbackReducer