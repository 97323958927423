import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { customerCashbackRecordListRequest } from '../../../redux/customer-cashback/customerCashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserEmployee, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { userBusinessListRequest } from "../../../redux/business/business.action";
import { businessLocationListRequest } from "../../../redux/business-location/businessLocation.action";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import { DateRangePicker } from 'react-date-range';
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const VendorCashbackList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { customerCashbackRecordListSuccess, customerCashbackRecordListError } = useSelector(state => state.customerCashback)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [showFilterOption, setShowFilterOption] = useState(false)
    const [isLast5TransactionSelected, setIsLast5TransactionSelected] = useState(false)
    const [isLast10TransactionSelected, setIsLast10TransactionSelected] = useState(false)
    const [isLast15TransactionSelected, setIsLast15TransactionSelected] = useState(false)
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false)
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (isLoggedInUserVendor(user)) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.VENDOR_CASHBACK_LIST, true)
            setLoading(true)
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data))
        } else if (isLoggedInUserEmployee(user)) {
            fetchCustomerCashbackList()
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(customerCashbackRecordListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(customerCashbackRecordListError)) {
            setLoading(false)
        }
    }, [customerCashbackRecordListSuccess, customerCashbackRecordListError])

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.VENDOR_CASHBACK_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.SCREEN.VENDOR_CASHBACK_LIST, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
            setSelectedLocation(businessLocationListSuccess.data[0])
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
            fetchCustomerCashbackList()
            // setLoading(true)
            // const data = {
            //     businessId: selectedBusiness.businessId
            // }
            // dispatch(businessLocationListRequest(data))
        }
    }, [selectedLocation])

    const fetchCustomerCashbackList = (isFilterAdded = false) => {
        if ((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation) || isLoggedInUserEmployee(user))) {
            setLoading(true);
            const data = {
                businessId: isLoggedInUserVendor(user) ? selectedBusiness.businessId : employeeSelectedBusiness,
                locationId: isLoggedInUserVendor(user) ? selectedLocation.locationId : employeeSelectedLocation
            };
            if (isFilterAdded === true && showFilterOption === true) {
                if (isLast5TransactionSelected === true) {
                    data.limit = 5
                }
                if (isLast10TransactionSelected === true) {
                    data.limit = 10
                }
                if (isLast15TransactionSelected === true) {
                    data.limit = 15
                }
                if (isDateRangeSelected === true) {
                    data.startDate = dateRange[0].startDate
                    data.endDate = dateRange[0].endDate
                }
            }
            dispatch(customerCashbackRecordListRequest(data));
        }
    }

    const handleApplyFilter = () => {
        if (isLast5TransactionSelected === false && isLast10TransactionSelected === false && isLast15TransactionSelected === false && isDateRangeSelected === false) {
            displayErrorToast('No filter option selected. Please try again')
        } else {
            setShowFilterOption(false)
            fetchCustomerCashbackList(true)
        }
    }

    const handleClearFilter = () => {
        setIsLast5TransactionSelected(false)
        setIsLast10TransactionSelected(false)
        setIsLast15TransactionSelected(false)
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
        setIsDateRangeSelected(false)
        setShowFilterOption(false)
        fetchCustomerCashbackList()
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Cashback Rewards' />
                {isLoggedInUserVendor(user) === true &&
                    <CommonInputRow className='margin-top-10'>
                        <div className="vcl-list-select-container">
                            <div className="vcl-list-select-heading">Select Business</div>
                            {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                                className='select-full-width-no-border'
                                value={selectedBusiness}
                                getOptionLabel={e => e.businessName}
                                getOptionValue={e => e}
                                options={userBusinessListSuccess.data}
                                placeholder='Select Business'
                                onChange={e => setSelectedBusiness(e)} />}
                            {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                        </div>
                        {!isEmpty(selectedBusiness) && <div className="vcl-list-select-container">
                            <div className="vcl-list-select-heading">Select Location</div>
                            {!isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <Select
                                className='select-full-width-no-border'
                                value={selectedLocation}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={businessLocationListSuccess.data}
                                placeholder='Select Location'
                                onChange={e => setSelectedLocation(e)} />}
                            {!isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                        </div>}
                    </CommonInputRow>}
                <div className="crl-filter-container">
                    <div className="crl-filter-btn-container">
                        <div className="crl-filter-btn" onClick={() => { setShowFilterOption(!showFilterOption) }}>{showFilterOption === false ? 'Show Filter' : 'Hide Filter'}</div>
                    </div>
                    {showFilterOption === true && <div className="crl-filter-container">
                        <div className="row crl-filter-row-center">
                            <div className={`col-sm-3 crl-filter-option-type-btn ${isLast5TransactionSelected === true ? 'crl-filter-option-type-btn-sel' : ''}`} onClick={() => {
                                setIsLast5TransactionSelected(!isLast5TransactionSelected)
                                setIsLast10TransactionSelected(false)
                                setIsLast15TransactionSelected(false)
                                setIsDateRangeSelected(false)
                            }}>Last 5 Records</div>
                            <div className={`col-sm-3 crl-filter-option-type-btn ${isLast10TransactionSelected === true ? 'crl-filter-option-type-btn-sel' : ''}`} onClick={() => {
                                setIsLast10TransactionSelected(!isLast10TransactionSelected)
                                setIsLast5TransactionSelected(false)
                                setIsLast15TransactionSelected(false)
                                setIsDateRangeSelected(false)
                            }}>Last 10 Records</div>
                            <div className={`col-sm-3 crl-filter-option-type-btn ${isLast15TransactionSelected === true ? 'crl-filter-option-type-btn-sel' : ''}`} onClick={() => {
                                setIsLast15TransactionSelected(!isLast15TransactionSelected)
                                setIsLast10TransactionSelected(false)
                                setIsLast5TransactionSelected(false)
                                setIsDateRangeSelected(false)
                            }}>Last 15 Records</div>
                            <div className={`col-sm-2 crl-filter-option-type-btn ${isDateRangeSelected === true ? 'crl-filter-option-type-btn-sel' : ''}`} onClick={() => {
                                setIsLast15TransactionSelected(false)
                                setIsLast10TransactionSelected(false)
                                setIsLast5TransactionSelected(false)
                                setIsDateRangeSelected(!isDateRangeSelected)
                            }}>Date Range</div>
                        </div>
                        <div className="crl-filter-btn-container">
                            <div className="crl-filter-btn crl-filter-btn-green" onClick={() => { handleApplyFilter() }}>Apply</div>
                            <div className="crl-filter-btn crl-filter-btn-red" onClick={() => { handleClearFilter() }}>Clear</div>
                        </div>
                        {isDateRangeSelected === true && <DateRangePicker
                            showMonthAndYearPickers={true}
                            showMonthArrow={true}
                            onChange={item => setDateRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRange}
                            direction="horizontal"
                        />}

                    </div>}
                </div>
                {!isNull(customerCashbackRecordListSuccess) && <div className="table-scroll-container">
                    {!isNull(customerCashbackRecordListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Customer Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Reward Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerCashbackRecordListSuccess.data.map(e => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">+{e.customerMobile}</td>
                                    <td className="rl-table-col-item text-center">{getAmountWithCurrency(e, e.cashbackAmount)}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isEmpty(customerCashbackRecordListError) && <div className="content-error">{customerCashbackRecordListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VendorCashbackList