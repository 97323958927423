import React from "react";
import './styles.css'
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const PasswordInputFull = ({ placeholder, value, onChange, isSubtext = false, subText }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2196f3',
                light: '#2196f3'
            }
        },
        typography: {
            fontSize: 13
        }
    })

    return (
        <div className='common-input-full'>
            <ThemeProvider theme={theme}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">{placeholder}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={value}
                        fullWidth
                        onChange={onChange}
                        size="medium"
                        color="primary"
                        inputProps={{ style: { backgroundColor: 'white' } }}
                        helperText={isSubtext ? subText : ''}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={
                                        showPassword ? 'hide the password' : 'display the password'
                                    }
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={placeholder}
                    />
                </FormControl>
            </ThemeProvider>
        </div>
        // <div className={`form-floating common-input-full ${classname}`}>
        //     <input
        //         type={type}
        //         className="form-control"
        //         id={id}
        //         placeholder={placeholder}
        //         value={value}
        //         onChange={onChange} />
        //     <label for={id}>{placeholder}</label>
        // </div>
    )
}

export default PasswordInputFull