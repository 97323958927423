import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
import { invoiceDetailRequest, invoiceUpdatePaymentRequest, invoiceUpdatePaymentRequestStatus } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { config } from '../../../config'
import Modal from 'react-bootstrap/Modal';
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";

const InvoiceDetail = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { state } = useLocation()
    const { invoiceDetailSuccess, invoiceDetailError,
        invoiceUpdatePaymentSuccess, invoiceUpdatePaymentError, invoiceUpdatePaymentReqStatus } = useSelector((state) => state.invoice)
    const dispatch = useDispatch()
    const [displayUpdatePaymentModal, setDisplayUpdatePaymentModal] = useState(false)
    const [paymentDropdown, setPaymentDropdown] = useState()
    const INVOICE_PAYMENT_STATUS_LIST = [
        { key: APP_CONSTANTS.PAYMENT_STATUS.PAID, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.PAYMENT_STATUS.PAID) },
        { key: APP_CONSTANTS.PAYMENT_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.PAYMENT_STATUS.ERROR) }
    ]
    const [showErrorInput, setShowErrorInput] = useState(false)
    const [errorInput, setErrorInput] = useState('')
    const [showPaymentModeInput, setShowPaymentModeInput] = useState(false)
    const [paymentModeInput, setPaymentModeInput] = useState('')
    const [displayReceiptModal, setDisplayReceiptModal] = useState(false)
    const [receiptImagePath, setReceiptImagePath] = useState('')

    useEffect(() => {
        fetchInvoiceDetail()
    }, [])

    const fetchInvoiceDetail = () => {
        setLoading(true)
        dispatch(invoiceDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(invoiceDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(invoiceDetailError)) {
            setLoading(false)
            displayErrorToast(invoiceDetailError.message)
        }
    }, [invoiceDetailSuccess, invoiceDetailError])

    useEffect(() => {
        if (invoiceUpdatePaymentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(invoiceUpdatePaymentSuccess.message)
            fetchInvoiceDetail()
        } else if (invoiceUpdatePaymentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(invoiceUpdatePaymentError.message)
        }
        if (invoiceUpdatePaymentReqStatus.length) {
            dispatch(invoiceUpdatePaymentRequestStatus(''))
        }
    }, [invoiceUpdatePaymentSuccess, invoiceUpdatePaymentError, invoiceUpdatePaymentReqStatus])

    const handleViewReceiptClick = item => {
        setReceiptImagePath(`${config.imageUrl}${item.image}`)
        setDisplayReceiptModal(true)
    }

    const handleUpdatePaymentStatusClick = () => {
        if (isEmpty(paymentDropdown)) {
            displayErrorToast('Payment status not selected')
        } else if (paymentDropdown.key === APP_CONSTANTS.PAYMENT_STATUS.PAID && isEmpty(paymentModeInput)) {
            displayErrorToast('Payment mode cannot be blank')
        } else if (paymentDropdown.key === APP_CONSTANTS.PAYMENT_STATUS.ERROR && isEmpty(errorInput)) {
            displayErrorToast('Error information cannot be blank')
        } else {
            let data = {
                invoiceId: invoiceDetailSuccess.data.invoiceId,
                paymentStatus: paymentDropdown.key
            }
            if (showPaymentModeInput === true) {
                data.paymentMode = paymentModeInput
            }
            if (showErrorInput === true) {
                data.reason = errorInput
            }
            setLoading(true)
            dispatch(invoiceUpdatePaymentRequest(data))
            setDisplayUpdatePaymentModal(false)
        }
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const getCustomerName = item => {
        if (isEmpty(item.customerFirstName) && isEmpty(item.customerLastName)) {
            return `+${item.customerMobile}`
        } else {
            return `${item.customerFirstName} ${item.customerLastName}`
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Invoice Details' />
                <CommonFormBorder>
                    <div className="ci-logo-parent-container">
                        <div className="ci-logo-container">
                            <img src={require('../../../assets/logo_gomint2.png')} className="img" />
                        </div>
                    </div>
                    {!isNull(invoiceDetailSuccess) && !isNull(invoiceDetailSuccess.data) &&
                        <div>
                            <div className="vccd-address-label mt-2 mb-2">Invoice Date {moment(invoiceDetailSuccess.data.createdOn).utc().format('DD MMM YYYY')}</div>
                            <div className="vccd-business-label">{invoiceDetailSuccess.data.businessDetails.businessName}</div>
                            <div className="vccd-address-label">{invoiceDetailSuccess.data.locationDetails.address}</div>
                            <div className="vccd-address-label">{invoiceDetailSuccess.data.locationDetails.city}, {invoiceDetailSuccess.data.locationDetails.state}, {invoiceDetailSuccess.data.locationDetails.country}</div>
                            {!isEmpty(invoiceDetailSuccess.data.operatorDetails) && <div>
                                <div className="ci-data-label mt-4">Operator Details</div>
                                <div className="ci-business-label">{invoiceDetailSuccess.data.operatorDetails.firstName} {invoiceDetailSuccess.data.operatorDetails.lastName}</div>
                                <div className="ci-address-label">{invoiceDetailSuccess.data.operatorDetails.email}</div>
                                <div className="ci-address-label">+{invoiceDetailSuccess.data.operatorDetails.mobile}</div>
                            </div>}
                            <div className="vccd-reward-container">
                                <div className="vccd-reward-label-container">Payable Amount</div>
                                <div className="vccd-reward-label-container">{getAmountWithCurrency(invoiceDetailSuccess.data, invoiceDetailSuccess.data.finalAmount)}</div>
                            </div>
                            <div className='form-field-label-grey'>Payment Status</div>
                            <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(invoiceDetailSuccess.data.paymentStatus)}</div>
                            {invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.PAID && <div>
                                <div className='form-field-label-grey'>Payment Mode</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.paymentMode ? removeSpecialCharAndChangeToPascal(invoiceDetailSuccess.data.paymentMode) : '-'}</div>
                            </div>}
                            {invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.ERROR &&
                                !isEmpty(invoiceDetailSuccess.data.errorReason) && <div>
                                    <div className='form-field-label-grey'>Error reason</div>
                                    <div className="form-field-text-label">{invoiceDetailSuccess.data.errorReason}</div>
                                </div>}
                            {!isEmpty(invoiceDetailSuccess.data.customerCashbackLogs) && <div>
                                <div className='form-field-label-grey'>Cashback Details</div>
                            </div>}
                            {!isEmpty(invoiceDetailSuccess.data.customerCashbackLogs) && <div className="table-scroll-container">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="rl-table-col-title">Offer</th>
                                            {/* <th scope="col" className="rl-table-col-title text-center">Offer Date</th> */}
                                            {/* <th scope="col" className="rl-table-col-title text-center">Brand Name</th> */}
                                            <th scope="col" className="rl-table-col-title text-center">Customer Number</th>
                                            <th scope="col" className="rl-table-col-title text-center">Cashback Date</th>
                                            <th scope="col" className="rl-table-col-title text-center">Cashback</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceDetailSuccess.data.customerCashbackLogs.map(item => (
                                            <tr className="rl-table-row-container">
                                                <td className="rl-table-col-item">
                                                    <div className="vccd-cl-brand-label">{item.brand}</div>
                                                    <div className="vccd-cl-offer-label">{item.offerName}</div>
                                                    <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                                                </td>
                                                {/* <td className="rl-table-col-item text-center">{moment(item.startDate).utc().format('Do MMM YY')} - {moment(item.endDate).utc().format('Do MMM YY')}</td> */}
                                                {/* <td className="rl-table-col-item text-center">{item.brand}</td> */}
                                                <td className="rl-table-col-item text-center align-middle">+{item.customerMobile}</td>
                                                <td className="rl-table-col-item text-center align-middle">{moment(item.createdOn).utc().format('Do MMM YY')}</td>
                                                <td className="rl-table-col-item text-center align-middle">{getAmountWithCurrency(invoiceDetailSuccess.data, item.casbackAmount)}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                            {/* {!isEmpty(invoiceDetailSuccess.data.customerCashbackLogs) && <div className="vccd-log-parent-container">
                                <div className='form-field-label-grey'>Cashback Details</div>
                                {invoiceDetailSuccess.data.customerCashbackLogs.map(item => (<div className="vccd-log-row-container">
                                    <div className="vccd-cl-brand-label">{item.brand}</div>
                                    <div className="vccd-cl-offer-label">{item.offerName}</div>
                                    <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                                    <div className="vccd-cl-customer-mobile-label">Customer Number: +{item.customerMobile}</div>
                                    <div className="vccd-cl-cashback-reward-amount">Cashback Rewarded: {getAmountWithCurrency(invoiceDetailSuccess.data, item.casbackAmount)}</div>
                                </div>))}
                            </div>} */}

                            {isLoggedInUserOperator(user) && invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.PENDING && <ButtonRow>
                                <CommonButton label='Update Payment Status'
                                    isBig={true}
                                    onClick={() => { setDisplayUpdatePaymentModal(true) }} />
                            </ButtonRow>}

                        </div >}
                </CommonFormBorder >
            </CommonScreenContent >
            <Modal
                onHide={() => { setDisplayReceiptModal(false) }}
                show={displayReceiptModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(receiptImagePath) && <div>
                        <img src={receiptImagePath} className="img" />
                    </div>}
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setDisplayUpdatePaymentModal(false) }}
                show={displayUpdatePaymentModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Payment Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={paymentDropdown}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={INVOICE_PAYMENT_STATUS_LIST}
                        placeholder='Select Payment Status'
                        onChange={e => {
                            setPaymentDropdown(e)
                            if (e.key === APP_CONSTANTS.PAYMENT_STATUS.PAID) {
                                setShowPaymentModeInput(true)
                            } else {
                                setShowPaymentModeInput(false)
                            }
                            if (e.key === APP_CONSTANTS.PAYMENT_STATUS.ERROR) {
                                setShowErrorInput(true)
                            } else {
                                setShowErrorInput(false)
                            }
                        }} />
                    {showErrorInput && <CommonInputFloatFull
                        type='text'
                        id='reasonInput'
                        onChange={e => setErrorInput(e.target.value)}
                        value={errorInput}
                        placeholder='Enter Error'
                    />}
                    {showPaymentModeInput && <CommonInputFloatFull
                        type='text'
                        id='successInput'
                        onChange={e => setPaymentModeInput(e.target.value)}
                        value={paymentModeInput}
                        placeholder='Payment Mode'
                    />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleUpdatePaymentStatusClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvoiceDetail