import React from "react";
import './styles.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import PercentOutlined from '@mui/icons-material/PercentOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const PercentInputFull = ({ placeholder, value, onChange, isSubtext = false, subText }) => {
    return (
        <div className='common-input-full'>
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{placeholder}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type='number'
                    value={value}
                    fullWidth
                    onChange={onChange}
                    size="medium"
                    color="primary"
                    inputProps={{ style: { backgroundColor: 'white' } }}
                    helperText={isSubtext ? subText : ''}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={'Percentage'}
                                edge="end"
                            >
                                <PercentOutlined />
                            </IconButton>
                        </InputAdornment>
                    }
                    label={placeholder}
                />
            </FormControl>
        </div>
    )
}

export default PercentInputFull