import { EmployeeActionTypes } from './employee.types'

//Add Employee
export const addEmployeeRequest = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST,
    payload: data
})

export const addEmployeeRequestSuccess = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_SUCCESS,
    payload: data
})

export const addEmployeeRequestError = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_ERROR,
    payload: data
})

export const addEmployeeRequestStatus = data => ({
    type: EmployeeActionTypes.ADD_EMPLOYEE_REQUEST_STATUS,
    payload: data
})

//Employee Login code check
export const employeeLoginCodeCheckRequest = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LOGIN_CODE_CHECK_REQUEST,
    payload: data
})

export const employeeLoginCodeCheckRequestSuccess = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_SUCCESS,
    payload: data
})

export const employeeLoginCodeCheckRequestError = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LOGIN_CODE_CHECK_REQUEST_ERROR,
    payload: data
})

//Employee List
export const employeeListRequest = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST,
    payload: data
})

export const employeeListRequestSuccess = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const employeeListRequestError = data => ({
    type: EmployeeActionTypes.EMPLOYEE_LIST_REQUEST_ERROR,
    payload: data
})

//Employee Detail
export const employeeDetailRequest = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST,
    payload: data
})

export const employeeDetailRequestSuccess = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const employeeDetailRequestError = data => ({
    type: EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST_ERROR,
    payload: data
})

//Remove Employee
export const removeEmployeeRequest = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST,
    payload: data
})

export const removeEmployeeRequestSuccess = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_SUCCESS,
    payload: data
})

export const removeEmployeeRequestError = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_ERROR,
    payload: data
})

export const removeEmployeeRequestStatus = data => ({
    type: EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST_STATUS,
    payload: data
})

//Update Employee Details
export const updateEmployeeDetailRequest = data => ({
    type: EmployeeActionTypes.UPDATE_EMPLOYEE_DETAIL_REQUEST,
    payload: data
})

export const updateEmployeeDetailRequestSuccess = data => ({
    type: EmployeeActionTypes.UPDATE_EMPLOYEE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const updateEmployeeDetailRequestError = data => ({
    type: EmployeeActionTypes.UPDATE_EMPLOYEE_DETAIL_REQUEST_ERROR,
    payload: data
})

export const updateEmployeeDetailRequestStatus = data => ({
    type: EmployeeActionTypes.UPDATE_EMPLOYEE_DETAIL_REQUEST_STATUS,
    payload: data
})

//Clear employee login code check
export const clearEmployeeLoginCheck = data => ({
    type: EmployeeActionTypes.CLEAR_EMPLOYEE_LOGIN_CODE_CHECK,
    payload: data
})