import React, { useState, useContext } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar'
import './sidebar.styles.scss'
import { Link, Navigate } from 'react-router-dom';
import { ROUTES } from '../../utils/AppConstants'
import {
    FaSignOutAlt, FaUserAlt, FaPercent, FaBook, FaRegUser, FaFileInvoiceDollar, FaUserCircle,
    FaMoneyCheckAlt, FaDonate, FaBuilding, FaMapMarkerAlt, FaBullhorn
} from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { clearLocalStorage, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserAdmin, isLoggedInUserVendor } from '../../utils/Utils';
import { checkFunctionalityPermission, PERMISSION_TYPE } from '../../utils/PermissionConstants';

const CustomSidebar = props => {
    const [isCollapsed, setCollapsed] = useState(window.innerWidth > 1024 ? false : true)
    const { isLogin, user, employeeSelectedBusiness, employeeSelectedLocation } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    return (
        <ProSidebar
            collapsed={isCollapsed}
        // toggled={false}
        // breakPoint="md"
        >
            <SidebarHeader>
                <div className='headerContainer' >
                    <div className='toggle-container'>
                        <div className='toggle' onClick={() => {
                            setCollapsed(!isCollapsed)
                        }}>
                            {isCollapsed ?
                                (<div>
                                    <img className='img' src={require('../../assets/hamburger_icon.png')} />
                                </div>) :
                                (<div>
                                    <img className='img' src={require('../../assets/hamburger_cross.png')} />
                                </div>)}
                        </div>
                    </div>
                    <div className='logo-container'>
                        {isCollapsed === false ? (<div className='img-container'>
                            <img className='img' src={require('../../assets/logo_gomint2.png')} />
                        </div>) : (
                            <div className='img-container-collapse'>
                                <img className='img' src={require('../../assets/ic_logo_gomint_small.png')} />
                            </div>
                        )}
                    </div>
                </div>
            </SidebarHeader>
            {isLogin && (<SidebarContent>
                <Menu iconShape='circle'>
                    {isLoggedInUserAdmin(user) && <MenuItem icon={<FaUserAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.OPERATOR_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.operatorList} />
                    </MenuItem>}
                    {checkFunctionalityPermission(user, PERMISSION_TYPE.BUSINESS, null, employeeSelectedBusiness, employeeSelectedLocation) === true && <MenuItem icon={<FaBuilding />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.BUSINESS_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.businessList} />
                    </MenuItem>}
                    {checkFunctionalityPermission(user, PERMISSION_TYPE.LOCATION, null, employeeSelectedBusiness, employeeSelectedLocation) === true && <MenuItem icon={<FaMapMarkerAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOCATION_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.locationList} />
                    </MenuItem>}
                    {(checkFunctionalityPermission(user, PERMISSION_TYPE.OFFERS, null, employeeSelectedBusiness, employeeSelectedLocation) === true || isLoggedInUserCustomer(user)) && <MenuItem icon={<FaBullhorn />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.OFFER_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.offerList} />
                    </MenuItem>}
                    {checkFunctionalityPermission(user, PERMISSION_TYPE.REWARD_CASHBACK, null, employeeSelectedBusiness, employeeSelectedLocation) === true && <MenuItem icon={<FaFileInvoiceDollar />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.REWARD_CASHBACK}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.addCustomerCashback} />
                    </MenuItem>}
                    {checkFunctionalityPermission(user, PERMISSION_TYPE.CUSTOMER_CASHBACK_REWARD, null, employeeSelectedBusiness, employeeSelectedLocation) === true && <MenuItem icon={<FaDonate />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.CUSTOMER_CASHBACK_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.vendorCashbackList} />
                    </MenuItem>}
                    {/* {!isLoggedInUserAdmin(user) && <MenuItem icon={<FaReceipt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.RECEIPT_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.receiptList} />
                    </MenuItem>} */}
                    {isLoggedInUserOperator(user) && <MenuItem icon={<FaMoneyCheckAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.VENDOR_CASHBACK_REWARDS}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.vendorCashbackRewards} />
                    </MenuItem>}
                    {(checkFunctionalityPermission(user, PERMISSION_TYPE.INVOICE, null, employeeSelectedBusiness, employeeSelectedLocation) === true || isLoggedInUserOperator(user)) && <MenuItem icon={<FaBook />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.INVOICE_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.invoiceList} />
                    </MenuItem>}
                    {isLoggedInUserCustomer(user) && <MenuItem icon={<FaMoneyCheckAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.CUSTOMER_CASHBACK_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.customerCashbackList} />
                    </MenuItem>}
                    {(isLoggedInUserOperator(user) || isLoggedInUserCustomer(user)) && <MenuItem icon={<FaFileInvoiceDollar />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.CASHBACK_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.cashbackRequestList} />
                    </MenuItem>}
                    {checkFunctionalityPermission(user, PERMISSION_TYPE.EMPLOYEE, null, employeeSelectedBusiness, employeeSelectedLocation) === true && <MenuItem icon={<FaRegUser />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.EMPLOYEE}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.employeeList} />
                    </MenuItem>}
                    {isLoggedInUserCustomer(user) && <MenuItem icon={<FaUserCircle />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.PROFILE}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.customerProfile} />
                    </MenuItem>}
                    <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                        clearLocalStorage()
                        dispatch({ type: APP_CONSTANTS.LOGOUT })
                    }}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }} onClick={() => {
                            clearLocalStorage()
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}
                        </div>
                    </MenuItem>
                </Menu>
            </SidebarContent>)}
            <SidebarFooter
                style={{ textAlign: 'center' }}>
                <div
                    className="sidebar-btn-wrapper"
                    style={{
                        padding: '10px 10px',
                    }}
                >
                    <span className='footer-text'>&#169; Copyright {new Date().getFullYear()}</span>
                </div>
            </SidebarFooter>
        </ProSidebar>
    )
}

export default CustomSidebar