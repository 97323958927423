import { CustomerCashbackActionTypes } from './customerCashback.types'

//Add Customer Cashback
export const addCustomerCashbackRequest = data => ({
    type: CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST,
    payload: data
})

export const addCustomerCashbackRequestSuccess = data => ({
    type: CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_SUCCESS,
    payload: data
})

export const addCustomerCashbackRequestError = data => ({
    type: CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_ERROR,
    payload: data
})

export const addCustomerCashbackRequestStatus = data => ({
    type: CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_STATUS,
    payload: data
})

//Customer Cashback List
export const customerCashbackListRequest = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST,
    payload: data
})

export const customerCashbackListRequestSuccess = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST_SUCCESS,
    payload: data
})

export const customerCashbackListRequestError = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST_ERROR,
    payload: data
})

//Customer Cashback Record List
export const customerCashbackRecordListRequest = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_RECORD_LIST_REQUEST,
    payload: data
})

export const customerCashbackRecordListRequestSuccess = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_RECORD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const customerCashbackRecordListRequestError = data => ({
    type: CustomerCashbackActionTypes.CUSTOMER_CASHBACK_RECORD_LIST_REQUEST_ERROR,
    payload: data
})