import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { addOfferRequest, addOfferRequestStatus } from '../../../redux/offer/offer.action'
import Select from 'react-select'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserVendor, isNull } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";
import { CATEGORIES } from "../../../utils/Categories";
import { userBusinessListRequest } from "../../../redux/business/business.action";
import { businessLocationListRequest } from "../../../redux/business-location/businessLocation.action";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import PercentInputFull from "../../../components/input/percent-input-full/percentInputFull";

const AddOffer = props => {
    const [isLoading, setLoading] = useState(false)
    const { addOfferSuccess, addOfferError, addOfferReqStatus, commonError } = useSelector((state) => state.offer)
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const dispatch = useDispatch()
    const [offerName, setOfferName] = useState('')
    const [claimDueDate, setClaimDueDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [minAmount, setMinAmount] = useState('')
    const [maxAmount, setMaxAmount] = useState('')
    const [brandName, setBrandName] = useState('')
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [currencyDropdown, setCurrencyDropdown] = useState()
    const [categoryDropdown, setCategoryDropdown] = useState()
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isFixedCashback, setIsFixedCashback] = useState(false)
    const [isPercentageCashback, setIsPercentageCashback] = useState(true)
    const [fixedCashbackAmount, setFixedCashbackAmount] = useState('')
    const [percentCashbackAmount, setPercentCashbackAmount] = useState('')

    useEffect(() => {
        // dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        if (isLoggedInUserVendor(user)) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_OFFER, true)
            setLoading(true)
            // dispatch(countryListRequest())
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data))
        }
    }, [])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    useEffect(() => {
        if (addOfferReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addOfferSuccess.message)
            clearFields()
        } else if (addOfferReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addOfferError.message)
        }
        if (addOfferReqStatus.length) {
            dispatch(addOfferRequestStatus(''))
        }
    }, [addOfferSuccess, addOfferError, addOfferReqStatus])

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.ADD_OFFER) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_OFFER, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
            setSelectedLocation(businessLocationListSuccess.data[0])
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    const clearFields = () => {
        setOfferName('')
        setClaimDueDate(new Date())
        setStartDate(new Date())
        setEndDate(new Date())
        setMinAmount('')
        setMaxAmount('')
        setBrandName('')
        setCountryDropdown();
        setStateDropdown();
        setCityDropdown();
        setCountryCode('')
        setImagePath(null)
        setImageName('')
        setIsFixedCashback(false)
        setIsPercentageCashback(false)
        setFixedCashbackAmount('')
        setPercentCashbackAmount('')
    }

    const handleSubmitClick = () => {
        if (isEmpty(offerName)) {
            displayErrorToast('Offer name cannot be blank')
        } else if (isEmpty(brandName)) {
            displayErrorToast('Offer brand name cannot be blank')
        } else if (isNull(categoryDropdown)) {
            displayErrorToast('Offer category not selected')
        } else if (isEmpty(startDate)) {
            displayErrorToast('Start date not selected')
        } else if (isEmpty(endDate)) {
            displayErrorToast('End date cannot not selected')
        } else if (isEmpty(minAmount)) {
            displayErrorToast('Minimum bill amount cannot be blank')
        } else if (Number(minAmount) < 0) {
            displayErrorToast('Minimum bill amount cannot be less than zero')
        } else if (isEmpty(maxAmount)) {
            displayErrorToast('Maximum bill amount cannot be blank')
        } else if (Number(maxAmount) < Number(minAmount)) {
            displayErrorToast('Maximum bill amount cannot be less than minimum bill amount')
        } else if (isPercentageCashback === false && isFixedCashback === false) {
            displayErrorToast('Cashback type not selected')
        } else if (isPercentageCashback === true && isEmpty(percentCashbackAmount)) {
            displayErrorToast('Cashback amount percentage cannot be blank')
        } else if (isPercentageCashback === true && !isEmpty(percentCashbackAmount) && parseFloat(percentCashbackAmount) < 0) {
            displayErrorToast('Invalid cashback amount percentage')
        } else if (isFixedCashback === true && isEmpty(fixedCashbackAmount)) {
            displayErrorToast('Fixed cashback amount cannot be blank')
        } else if (isFixedCashback === true && !isEmpty(fixedCashbackAmount) && parseFloat(fixedCashbackAmount) < 0) {
            displayErrorToast('Invalid fixed cashback amount')
        } else if (isLoggedInUserVendor(user) && isEmpty(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isLoggedInUserVendor(user) && isEmpty(selectedLocation)) {
            displayErrorToast('Business location not selected')
        } else if (isNull(imagePath)) {
            displayErrorToast('Offer image not selected')
        } else {
            let fd = new FormData()
            fd.append('businessId', isLoggedInUserVendor(user) ? selectedBusiness.businessId : employeeSelectedBusiness)
            fd.append('locationId', isLoggedInUserVendor(user) ? selectedLocation.locationId : employeeSelectedLocation)
            fd.append('offerName', offerName)
            fd.append('brand', brandName)
            fd.append('categoryId', categoryDropdown.categoryId)
            fd.append('categoryKey', categoryDropdown.categoryKey)
            fd.append('startDate', moment(startDate).utc().format("YYYY-MM-DD hh:mm:ss A"))
            fd.append('endDate', moment(endDate).utc().format("YYYY-MM-DD hh:mm:ss A"))
            // fd.append('claimDueDate', moment(claimDueDate).utc().format("YYYY-MM-DD hh:mm:ss A"))
            fd.append('minimumReceiptAmount', minAmount)
            fd.append('maximumReceiptAmount', maxAmount)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('isCashbackByPercent', isPercentageCashback)
            fd.append('isCashbackByAmount', isFixedCashback)
            fd.append('cashbackPercent', percentCashbackAmount)
            fd.append('cashbackAmount', fixedCashbackAmount)
            // fd.append('country', countryDropdown.name)
            // fd.append('state', stateDropdown.name)
            // fd.append('city', cityDropdown.name)
            // fd.append('currencyLabel', currencyDropdown.code)
            // fd.append('currencySymbol', currencyDropdown.symbol)
            // fd.append('currencyRegion', currencyDropdown.region)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            setLoading(true)
            dispatch(addOfferRequest(fd))
        }
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    useEffect(() => {
        if (!isNull(categoryDropdown)) {
            console.log('category', categoryDropdown)
        }
    }, [categoryDropdown])

    const handleCashbackTypeChoiceChange = type => {
        if (type === 'PERCENTAGE') {
            setIsPercentageCashback(true)
            setIsFixedCashback(false)
        } else if (type === 'FIXED') {
            setIsPercentageCashback(false)
            setIsFixedCashback(true)
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Offer' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new offer' />
                    <FormFieldLabel className='up-bold-label' label='Offer Information' />
                    <CommonInputFloatFull
                        type='text'
                        id='offerName'
                        onChange={e => setOfferName(e.target.value)}
                        placeholder='Offer Name'
                        value={offerName}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='brand'
                        onChange={e => setBrandName(e.target.value)}
                        placeholder='Brand Name'
                        value={brandName}
                        subText='Eg. Nike, Pepsi etc.'
                        isSubtext={true}
                    />
                    <FormFieldLabel label='Select Category' />
                    {CATEGORIES && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={categoryDropdown}
                        getOptionLabel={e => `${e.name}`}
                        getOptionValue={e => e}
                        options={CATEGORIES}
                        placeholder='Select Category'
                        onChange={e => {
                            setCategoryDropdown(e)
                        }}
                    />}
                    <CommonInputRow>
                        <div className="as-date-container">
                            <FormFieldLabel label='Start Date' />
                            <DatePicker
                                className="as-date-picker"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                        <div className="as-date-container">
                            <FormFieldLabel label='End Date' />
                            <DatePicker
                                minDate={startDate}
                                className="as-date-picker"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                    </CommonInputRow>
                    {/* <div className="as-claim-date-container">
                        <FormFieldLabel label='Offer Claim Due Date' />
                        <DatePicker
                            className="as-date-picker"
                            minDate={endDate}
                            selected={claimDueDate}
                            onChange={(date) => setClaimDueDate(date)}
                            showTimeSelect={false}
                        />
                    </div> */}
                    <FormFieldLabel className='up-bold-label' label='Cashback Information' />
                    <FormFieldLabel label='Shopping Amount Range' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='number'
                            id='minAmount'
                            onChange={e => setMinAmount(e.target.value)}
                            placeholder='Min. Amount'
                            value={minAmount}
                        />
                        <CommonInputFloatHalf
                            type='number'
                            id='maxAmount'
                            onChange={e => setMaxAmount(e.target.value)}
                            placeholder='Max. Amount'
                            value={maxAmount}
                        />
                    </CommonInputRow>
                    <div className="ad-rb-row-container">
                        <div className="ad-rb-row-label">Cashback Type:</div>
                        <div className="ap-option-item-container">
                            <label className="ap-choice-label-container">
                                <input type='radio' value='PERCENTAGE' checked={isPercentageCashback} onChange={e => handleCashbackTypeChoiceChange(e.target.value)} />
                                <span className="ap-choice-label">On Percent Basis</span>
                            </label>
                            <label className="ap-choice-label-container">
                                <input type='radio' value='FIXED' checked={isFixedCashback} onChange={e => handleCashbackTypeChoiceChange(e.target.value)} />
                                <span className="ap-choice-label">Fixed Amount</span>
                            </label>
                        </div>
                    </div>
                    {isFixedCashback && <CommonInputFloatFull
                        type='number'
                        id='fixedCashback'
                        onChange={e => setFixedCashbackAmount(e.target.value)}
                        placeholder='Fixed Cashback Amount'
                        value={fixedCashbackAmount}
                    />}
                    {isPercentageCashback && <PercentInputFull
                        type='number'
                        id='percentageCashback'
                        onChange={e => setPercentCashbackAmount(e.target.value)}
                        placeholder='Cashback Amount Percent'
                        value={percentCashbackAmount}
                    />}
                    {isLoggedInUserVendor(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Business Information' />
                        <FormFieldLabel label='Select Business' />
                        {userBusinessListSuccess && userBusinessListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedBusiness}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={userBusinessListSuccess.data}
                            placeholder='Select Business'
                            onChange={e => setSelectedBusiness(e)} />}
                        {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                        {!isEmpty(selectedBusiness) && businessLocationListSuccess && businessLocationListSuccess.data && <FormFieldLabel label='Select Business Location' />}
                        {!isEmpty(selectedBusiness) && businessLocationListSuccess && businessLocationListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={businessLocationListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                    </div>}
                    {/* <FormFieldLabel className='form-field-label-grey' label='Select Currency' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={currencyDropdown}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setCurrencyDropdown(e)
                        }}
                    />} */}
                    {/* <FormFieldLabel className='up-bold-label' label='Select Offer Location' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow> */}
                    <FormFieldLabel className='up-bold-label' label='Select Offer Image' />
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>

                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => handleSubmitClick()} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddOffer