import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    employeeListRequest, removeEmployeeRequest, removeEmployeeRequestStatus
} from '../../../redux/employee/employee.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserVendor, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { userBusinessListRequest } from '../../../redux/business/business.action'
import { businessLocationListRequest } from '../../../redux/business-location/businessLocation.action'
import Select from 'react-select'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TopButton from '../../../components/button/top-button/topButton'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import { checkFunctionalityPermission, PERMISSION_SUB_TYPE, PERMISSION_TYPE } from "../../../utils/PermissionConstants";

const EmployeeList = props => {
    const { user, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { employeeListSuccess, employeeListError,
        removeEmployeeSuccess, removeEmployeeError, removeEmployeeStatus } = useSelector(state => state.employee)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let navigate = useNavigate();

    const EMPLOYEE_LIST = 'EMPLOYEE_LIST'
    const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
    const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'

    useEffect(() => {
        if (!isNull(employeeListSuccess)) {
            setLoading(false)
        } else if (!isNull(employeeListError)) {
            setLoading(false)

        }
    }, [employeeListSuccess, employeeListError])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.EMPLOYEE_LIST, true)
            const data1 = {
                userId: user.data.userDetails.userId
            }
            setLoading(true)
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data))
        }
    }, [])

    const fetchEmployeeList = () => {
        if (isLoggedInUserVendor(user) === true) {
            if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
                setLoading(true)
                const data = {
                    businessId: selectedBusiness.businessId,
                    locationId: selectedLocation.locationId
                }
                dispatch(employeeListRequest(data))
            } else {
                displayErrorToast('Business and location is not selected')
            }
        } else {
            setLoading(true)
            const data = {
                businessId: employeeSelectedBusiness,
                locationId: employeeSelectedLocation
            }
            dispatch(employeeListRequest(data))
        }
    }

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.EMPLOYEE_LIST) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.SCREEN.EMPLOYEE_LIST, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isNull(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(businessLocationListSuccess)) {
            setLoading(false)
            setSelectedLocation(businessLocationListSuccess.data[0])
        } else if (!isNull(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (removeEmployeeStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(removeEmployeeSuccess.message)
            fetchEmployeeList()
        }
        if (removeEmployeeStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displaySuccessToast(removeEmployeeError.message)
        }
        if (removeEmployeeStatus?.length) {
            dispatch(removeEmployeeRequestStatus(''))
        }
    }, [removeEmployeeSuccess, removeEmployeeError, removeEmployeeStatus])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(selectedLocation)) {
            fetchEmployeeList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (isLoggedInUserEmployee(user) === true && !isNull(employeeSelectedBusiness) && !isNull(employeeSelectedLocation)) {
            fetchEmployeeList()
        }
    }, [employeeSelectedBusiness, employeeSelectedLocation])

    const handleCardClick = item => {
        if (checkFunctionalityPermission(user, PERMISSION_TYPE.EMPLOYEE, PERMISSION_SUB_TYPE.EMPLOYEE.REMOVE_EMPLOYEE, employeeSelectedBusiness, employeeSelectedLocation) === true === true) {
            const data = {
                employeeId: item.employeeId
            }
            if (isLoggedInUserVendor(user) === true) {
                data.businessId = selectedBusiness.businessId
                data.locationId = selectedLocation.locationId
            }
            if (isLoggedInUserEmployee(user) === true) {
                data.businessId = employeeSelectedBusiness
                data.locationId = employeeSelectedLocation
            }
            setLoading(true)
            dispatch(removeEmployeeRequest(data))
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const handleDetailClick = item => {
        // if (isLoggedInUserEmployee(user)) {
        //     if (parseFloat(item.employeeId) === parseFloat(user.data.userDetails.employeeId)) {
        //         displayErrorToast('Cannot view self details')
        //     } else {
        //         navigate(ROUTES.editEmployee, { state: item.employeeId })
        //     }
        // } else {
        // }
        navigate(ROUTES.editEmployee, { state: item.employeeId })
    }

    const handleAddEmpClick = () => {
        if (checkFunctionalityPermission(user, PERMISSION_TYPE.EMPLOYEE, PERMISSION_SUB_TYPE.EMPLOYEE.ADD_NEW_EMPLOYEE, employeeSelectedBusiness, employeeSelectedLocation) === true) {
            navigate(ROUTES.addEmployee)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Employees' />
                    <TopButton label='Add Employee'
                        onClick={() => { handleAddEmpClick() }} />
                </CommonInputRow>
                {isLoggedInUserVendor(user) === true && <CommonInputRow className='margin-top-10'>
                    <div className="el-select-container">
                        <div className="el-select-heading">Select Business</div>
                        {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                            className='el-list-select'
                            value={selectedBusiness}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={userBusinessListSuccess.data}
                            placeholder='Select Business'
                            onChange={e => setSelectedBusiness(e)} />}
                        {!isEmpty(userBusinessListError) && <div className="p-text">{userBusinessListError.message}</div>}
                    </div>
                    <div className="el-select-container">
                        <div className="el-select-heading">Select Location</div>
                        {!isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <Select
                            className='el-list-select'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={businessLocationListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!isEmpty(businessLocationListError) && <div className="p-text">{businessLocationListError.message}</div>}
                    </div>
                </CommonInputRow>}
                {<div className="table-scroll-container">
                    {employeeListSuccess && employeeListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Mobile</th>
                                <th scope="col" className="rl-table-col-title text-center">Email</th>
                                <th scope="col" className="rl-table-col-title text-center">Employee Code</th>
                                <th scope="col" className="rl-table-col-title text-center">Login Code</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.firstName} {element.lastName}</td>
                                    <td className="rl-table-col-item text-center">{!isEmpty(element.mobile) ? `+${element.mobile}` : '-'}</td>
                                    <td className="rl-table-col-item text-center">{!isEmpty(element.email) ? element.email : '-'}</td>
                                    <td className="rl-table-col-item text-center">{element.employeeCode}</td>
                                    <td className="rl-table-col-item text-center">{element.employeeLoginCode}</td>
                                    {/* <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td> */}
                                    <td className="rl-table-col-item text-center" >
                                        <div className="el-row-container">
                                            <div className="resl-btn-container me-3" onClick={() => { handleDetailClick(element) }}><div className="tr-action-btn">Details</div></div>
                                            <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}><div className="tr-action-btn">Remove</div></div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isEmpty(employeeListError) && <div className="content-error">{employeeListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeList