import { isEmpty, isLoggedInUserEmployee, isLoggedInUserVendor } from "./Utils"

export const PERMISSION_TYPE = {
    BUSINESS: 'BUSINESS',
    LOCATION: 'LOCATION',
    OFFERS: 'OFFERS',
    REWARD_CASHBACK: 'REWARD_CASHBACK',
    CUSTOMER_CASHBACK_REWARD: 'CUSTOMER_CASHBACK_REWARD',
    INVOICE: 'INVOICE',
    EMPLOYEE: 'EMPLOYEE'
}

export const PERMISSION_SUB_TYPE = {
    BUSINESS: {
        ADD_NEW_BUSINESS: 'addNewBusiness',
        EDIT_BUSINESS_DETAIL: 'editBusinessDetail'
    },
    LOCATION: {
        ADD_NEW_LOCATION: 'addNewLocation',
        EDIT_LOCATION_DETAIL: 'editLocationDetail'
    },
    OFFERS: {
        EDIT_OFFER_DETAIL: 'editOfferDetail',
        ADD_NEW_OFFER: 'addNewOffer'
    },
    INVOICE: {
        VIEW_INVOICE_DETAIL: 'viewInvoiceDetail'
    },
    EMPLOYEE: {
        ADD_NEW_EMPLOYEE: 'addNewEmployee',
        REMOVE_EMPLOYEE: 'removeEmployee',
        UPDATE_DETAILS: 'updateDetails'
    }
}

export const checkFunctionalityPermission = (user, permissionType, permissionSubType, employeeSelectedBusiness, employeeSelectedLocation) => {
    if (isLoggedInUserVendor(user)) {
        return true
    } else if (isLoggedInUserEmployee(user)) {
        const itemObj = user.data.userDetails.permissions.find(item => (item.type === permissionType && item.businessId === employeeSelectedBusiness && item.locationId === employeeSelectedLocation))
        if (!isEmpty(itemObj)) {
            if (!isEmpty(permissionSubType)) {
                if (!isEmpty(itemObj[permissionSubType]) && itemObj[permissionSubType] === true) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return false
        }
    } else {
        return false
    }
}