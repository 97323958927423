import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addEmployeeRequestAPI, employeeDetailAPI, employeeListAPI, employeeLoginCodeCheckAPI,
    removeEmployeeAPI, updateEmployeeDetailAPI
} from '../api-requests/employee';
import {
    addEmployeeRequestError, addEmployeeRequestSuccess, employeeDetailRequestError,
    employeeDetailRequestSuccess, employeeListRequestError, employeeListRequestSuccess,
    employeeLoginCodeCheckRequestError, employeeLoginCodeCheckRequestSuccess, removeEmployeeRequestError,
    removeEmployeeRequestSuccess, updateEmployeeDetailRequestError, updateEmployeeDetailRequestSuccess
} from './employee.action';
import { EmployeeActionTypes } from './employee.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateEmployeeReq({ payload }) {
    try {
        const response = yield call(updateEmployeeDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateEmployeeDetailRequestSuccess(decryptedData))
            } else {
                yield put(updateEmployeeDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateEmployeeDetailRequestError(error.response.data))
        }
    }
}

export function* updateEmployeeDetailReq() {
    yield takeLatest(EmployeeActionTypes.UPDATE_EMPLOYEE_DETAIL_REQUEST, handleUpdateEmployeeReq)
}

function* handleRemoveEmployeeReq({ payload }) {
    try {
        const response = yield call(removeEmployeeAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(removeEmployeeRequestSuccess(decryptedData))
            } else {
                yield put(removeEmployeeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(removeEmployeeRequestError(error.response.data))
        }
    }
}

export function* removeEmployeeReq() {
    yield takeLatest(EmployeeActionTypes.REMOVE_EMPLOYEE_REQUEST, handleRemoveEmployeeReq)
}

function* handleEmployeeLoginCodeCheckReq({ payload }) {
    try {
        const response = yield call(employeeLoginCodeCheckAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(employeeLoginCodeCheckRequestSuccess(decryptedData))
            } else {
                yield put(employeeLoginCodeCheckRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(employeeLoginCodeCheckRequestError(error.response.data))
        }
    }
}

export function* employeeLoginCodeCheckReq() {
    yield takeLatest(EmployeeActionTypes.EMPLOYEE_LOGIN_CODE_CHECK_REQUEST, handleEmployeeLoginCodeCheckReq)
}

function* handleEmployeeListReq({ payload }) {
    try {
        const response = yield call(employeeListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(employeeListRequestSuccess(decryptedData))
            } else {
                yield put(employeeListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(employeeListRequestError(error.response.data))
        }
    }
}

export function* employeeListReq() {
    yield takeLatest(EmployeeActionTypes.EMPLOYEE_LIST_REQUEST, handleEmployeeListReq)
}

function* handleEmployeeDetailReq({ payload }) {
    try {
        const response = yield call(employeeDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(employeeDetailRequestSuccess(decryptedData))
            } else {
                yield put(employeeDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(employeeDetailRequestError(error.response.data))
        }
    }
}

export function* employeeDetailReq() {
    yield takeLatest(EmployeeActionTypes.EMPLOYEE_DETAIL_REQUEST, handleEmployeeDetailReq)
}

function* handleAddEmployeeRequestReq({ payload }) {
    try {
        const response = yield call(addEmployeeRequestAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addEmployeeRequestSuccess(decryptedData))
            } else {
                yield put(addEmployeeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EmployeeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addEmployeeRequestError(error.response.data))
        }
    }
}

export function* addEmployeeReq() {
    yield takeLatest(EmployeeActionTypes.ADD_EMPLOYEE_REQUEST, handleAddEmployeeRequestReq)
}