import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { cashbackListRequest, cashbackUpdateStatusRequest, cashbackUpdateStatusRequestStatus } from '../../../redux/cashback/cashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserEmployee, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import { userBusinessListRequest } from "../../../redux/business/business.action";
import { businessLocationListRequest } from "../../../redux/business-location/businessLocation.action";
import { offerListRequest } from "../../../redux/offer/offer.action";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { searchOrAddCustomerRequest, clearCustomerResponseData } from "../../../redux/customer/customer.action";
import { addCustomerCashbackRequest, addCustomerCashbackRequestStatus } from "../../../redux/customer-cashback/customerCashback.action";

const AddCustomerCashback = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { addCustomerCashbackSuccess, addCustomerCashbackError, addCustomerCashbackReqStatus } = useSelector(state => state.customerCashback)
    const { offerListSuccess, offerListError, commonError } = useSelector((state) => state.offer)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const { searchOrAddCustomerSuccess, searchOrAddCustomerError } = useSelector(state => state.customer)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [offerDropdown, setOfferDropdown] = useState()
    const [billAmount, setBillAmount] = useState('')
    const [cashbackAmount, setCashbackAmount] = useState('')
    const [mobile, setMobile] = useState()
    const [showCustomerNumberView, setShowCustomerNumberView] = useState(true)
    const [showCashbackRewardView, setShowCashbackRewardView] = useState(false)

    useEffect(() => {
        if (isLoggedInUserVendor(user)) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_CASHBACK, true)
            setLoading(true)
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data))
        }
        if (isLoggedInUserEmployee(user)) {
            fetchOfferList()
        }
    }, [])

    useEffect(() => {
        if (addCustomerCashbackReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addCustomerCashbackSuccess.message)
            clearFields()
            setShowCashbackRewardView(false)
            setShowCustomerNumberView(true)
        } else if (addCustomerCashbackReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addCustomerCashbackError.message)
        }
        if (addCustomerCashbackReqStatus?.length) {
            dispatch(addCustomerCashbackRequestStatus(''))
        }
    }, [addCustomerCashbackSuccess, addCustomerCashbackError, addCustomerCashbackReqStatus])

    const clearFields = () => {
        setBillAmount('')
        setCashbackAmount('')
        setMobile()
        dispatch(clearCustomerResponseData(''))
    }

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.ADD_CASHBACK) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_CASHBACK, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
            setSelectedLocation(businessLocationListSuccess.data[0])
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isEmpty(offerListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(offerListError)) {
            setLoading(false)
        }
    }, [offerListSuccess, offerListError])

    useEffect(() => {
        if (!isEmpty(searchOrAddCustomerSuccess)) {
            setLoading(false)
            setShowCustomerNumberView(false)
            setShowCashbackRewardView(true)
        } else if (!isEmpty(searchOrAddCustomerError)) {
            setLoading(false)
            displayErrorToast(searchOrAddCustomerError.message)
        }
    }, [searchOrAddCustomerSuccess, searchOrAddCustomerError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
            fetchOfferList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (!isNull(offerListSuccess)) {
            setLoading(false)
            const onGoingOffer = offerListSuccess.data.find(e => isOfferExpired(e.endDate, e.currentTime) === false)
            if (!isEmpty(onGoingOffer)) {
                setOfferDropdown(onGoingOffer)
            }
        } else if (!isNull(offerListError)) {
            setLoading(false)
        }
    }, [offerListSuccess, offerListError])

    const fetchOfferList = () => {
        let data = {}
        if (isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) {
            data.businessId = selectedBusiness.businessId
            data.locationId = selectedLocation.locationId
        }
        if (isLoggedInUserEmployee(user)) {
            data.businessId = employeeSelectedBusiness
            data.locationId = employeeSelectedLocation
        }
        setLoading(true)
        dispatch(offerListRequest(data))
    }

    const isOfferExpired = (offerEndDate, currentDate) => {
        // const momen1 = new Date(offerEndDate)
        // const moment2 = new Date(currentDate)
        // if (momen1.getTime() > moment2.getTime()) {
        //     return true
        // } else {
        //     return false
        // }
        if (moment(currentDate).isAfter(moment(offerEndDate))) {
            return true
        } else {
            return false
        }
    }

    const handleSearchCustomerClick = () => {
        if (isNull(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else {
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                mobile: parseInt(mobileWithPlusSymbol)
            }
            setLoading(true)
            dispatch(searchOrAddCustomerRequest(data))
        }
    }

    const handleSubmitClick = () => {
        if (isLoggedInUserVendor(user) && isEmpty(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isLoggedInUserVendor(user) && isEmpty(selectedLocation)) {
            displayErrorToast('Business location not selected')
        } else if (isEmpty(offerDropdown)) {
            displayErrorToast('Offer not selected')
        } else if (isEmpty(searchOrAddCustomerSuccess)) {
            displayErrorToast('Customer not selected')
        } else if (isEmpty(billAmount)) {
            displayErrorToast('Bill Receipt amount cannot be blank')
        } else if (isEmpty(cashbackAmount)) {
            displayErrorToast('Cashback amount cannot be blank')
        } else if (parseFloat(cashbackAmount) <= 0) {
            displayErrorToast('Invalid cashback amount')
        } else {
            const data = {
                businessId: isLoggedInUserVendor(user) ? selectedBusiness.businessId : employeeSelectedBusiness,
                locationId: isLoggedInUserVendor(user) ? selectedLocation.locationId : employeeSelectedLocation,
                cashbackAmount: parseFloat(cashbackAmount),
                billReceiptAmount: parseFloat(billAmount),
                offerId: offerDropdown.offerId,
                customerId: searchOrAddCustomerSuccess.data.customerId
            }
            setLoading(true)
            dispatch(addCustomerCashbackRequest(data))

        }
    }

    useEffect(() => {
        if (!isEmpty(billAmount) && parseFloat(billAmount) > 0 && !isEmpty(offerDropdown)) {
            if (offerDropdown.isCashbackByAmount === true) {
                if (parseFloat(billAmount) >= parseFloat(offerDropdown.minimumReceiptAmount) &&
                    parseFloat(billAmount) <= parseFloat(offerDropdown.maximumReceiptAmount) &&
                    !isEmpty(offerDropdown.cashbackAmount)) {
                    setCashbackAmount(parseFloat(offerDropdown.cashbackAmount))
                } else {
                    setCashbackAmount(0)
                }
            } else if (offerDropdown.isCashbackByPercent === true) {
                if (parseFloat(billAmount) >= parseFloat(offerDropdown.minimumReceiptAmount) &&
                    parseFloat(billAmount) <= parseFloat(offerDropdown.maximumReceiptAmount) &&
                    !isEmpty(offerDropdown.cashbackPercent)) {
                    const cbAmount = (parseFloat(offerDropdown.cashbackPercent) / 100) * parseFloat(billAmount)
                    setCashbackAmount(cbAmount)
                } else {
                    setCashbackAmount(0)
                }
            }
        } else {
            setCashbackAmount(0)
        }
    }, [billAmount])

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add/Reward Cashback' />
                {showCustomerNumberView === true && <CommonFormBorder>
                    <FormFieldLabel label='Enter customer number to continue' className='mb-4' />
                    <div className="acc-mobile-container">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <ButtonRow>
                        <CommonButton label='Next' onClick={() => { handleSearchCustomerClick() }} />
                    </ButtonRow>
                </CommonFormBorder>}
                {showCashbackRewardView === true && <CommonFormBorder>
                    <SubTextLabel label='Enter details to add customer cashback' />
                    {isLoggedInUserVendor(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Business Information' />
                        <FormFieldLabel label='Select Business' />
                        {userBusinessListSuccess && userBusinessListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedBusiness}
                            getOptionLabel={e => e.businessName}
                            getOptionValue={e => e}
                            options={userBusinessListSuccess.data}
                            placeholder='Select Business'
                            onChange={e => setSelectedBusiness(e)} />}
                        {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                        {!isEmpty(selectedBusiness) && <FormFieldLabel label='Select Business Location' />}
                        {!isEmpty(selectedBusiness) && businessLocationListSuccess && businessLocationListSuccess.data && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={selectedLocation}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={businessLocationListSuccess.data}
                            placeholder='Select Location'
                            onChange={e => setSelectedLocation(e)} />}
                        {!isEmpty(selectedBusiness) && !isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                    </div>}
                    {((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) || isLoggedInUserEmployee(user)) && <FormFieldLabel className='up-bold-label' label='Offer Information' />}
                    {((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) || isLoggedInUserEmployee(user)) && offerListSuccess && offerListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={offerDropdown}
                        getOptionLabel={e => `${e.offerName} ${isOfferExpired(e.endDate, e.currentTime) ? ' - Expired' : ''}`}
                        getOptionValue={e => e}
                        options={offerListSuccess.data}
                        placeholder='Select Offer'
                        onChange={e => setOfferDropdown(e)} />}
                    {((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) || isLoggedInUserEmployee(user)) && !isEmpty(offerListError) && <div className="content-error">{offerListError.message}</div>}
                    {/* {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerDropdown) && <FormFieldLabel label='Search Customer' />} */}
                    {/* {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerDropdown) && <div className="acc-mobile-row">
                        <div className="acc-mobile-container">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile} />
                        </div>
                        <div className="acc-mobile-search-img-container" onClick={() => { handleSearchCustomerClick() }}>
                            <img src={require('../../../assets/ic_search_icon.png')} className="img" />
                        </div>
                    </div>} */}
                    {((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) || isLoggedInUserEmployee(user)) && !isEmpty(offerDropdown) && !isEmpty(searchOrAddCustomerSuccess) && <CommonInputFloatFull
                        type='number'
                        id='billAmount'
                        onChange={e => setBillAmount(e.target.value)}
                        placeholder='Bill Receipt Amount'
                        value={billAmount}
                    />}
                    {((isLoggedInUserVendor(user) && !isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) || isLoggedInUserEmployee(user)) && !isEmpty(offerDropdown) && !isEmpty(searchOrAddCustomerSuccess) && !isEmpty(billAmount) && <CommonInputFloatFull
                        type='number'
                        id='cashbackAmount'
                        onChange={e => setCashbackAmount(e.target.value)}
                        placeholder='Cashback Amount'
                        value={cashbackAmount}
                    />}
                    <ButtonRow>
                        <CommonButton label='Submit' onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>}

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddCustomerCashback