import { combineReducers } from 'redux'
import userReducer from './user/user.reducer'
import cashbackReducer from './cashback/cashback.reducer'
import customerReducer from './customer/customer.reducer'
import businessReducer from './business/business.reducer'
import businessLocationReducer from './business-location/businessLocation.reducer'
import offerReducer from './offer/offer.reducer'
import customerCashbackReducer from './customer-cashback/customerCashback.reducer'
import invoiceReducer from './invoice/invoice.reducer'
import employeeReducer from './employee/employee.reducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer,
    offer: offerReducer,
    cashback: cashbackReducer,
    customer: customerReducer,
    business: businessReducer,
    businessLocation: businessLocationReducer,
    invoice: invoiceReducer,
    customerCashback: customerCashbackReducer,
    employee: employeeReducer
})

// export default rootReducer
export default persistReducer(persistConfig, rootReducer)