import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function userRegistrationAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userRegistration}`,
        data: payload
    })
}

export function customerLoginAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.customerLogin}`,
        data: payload
    })
}

export function userLoginAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.login}`,
        data: payload
    })
}

export function forgotPasswordAPI(payload) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.forgotPassword}`,
        data: payload
    })
}
export function changePasswordAPI(payload) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.changePassword}`,
        data: payload
    })
}

export function operatorRegistrationAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorRegistration}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid,
            "Content-Type": "multipart/form-data"
        }
    })
}

export function subscriptionList() {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.subscriptionList}`
    })
}

export function operatorList() {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorList}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function operatorDetail(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.operatorDetail}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateOperatorLocations(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateOperatorLocation}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateNotificationTokenAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateNotificationToken}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid,
        }
    })
}

export function employeeLoginRequestAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.employee.employeeLogin}`,
        data: payload
    })
}

export function countryListApi() {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function stateListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function cityListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}/${data.stateId}/${API_ENDPOINTS.location.cityList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}