import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { employeeBusinessAndLocSelectionPayload } from '../../../redux/user/user.action'
import Select from 'react-select'
import { ROUTES } from "../../../utils/AppConstants";
import { displayErrorToast, isEmpty, isNull } from "../../../utils/Utils";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";

const EmployeeSelectLocation = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [filteredLocationList, setFilteredLocationList] = useState(null)
    let navigate = useNavigate();

    useEffect(() => {
        if (!isEmpty(user)) {
            if (!isEmpty(user.data.userDetails.businessList)) {
                setSelectedBusiness(user.data.userDetails.businessList[0])
            }
        }
    }, [user])


    const submitHandler = () => {
        if (isNull(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isEmpty(selectedLocation)) {
            displayErrorToast('Business location not selected')
        } else {
            const data = {
                businessId: selectedBusiness.businessId,
                locationId: selectedLocation.locationId
            }
            dispatch(employeeBusinessAndLocSelectionPayload(data))
            navigate(ROUTES.offerList, { replace: true });
        }
    }

    useEffect(() => {
        if (!isEmpty(selectedBusiness)) {
            if (!isEmpty(user.data.userDetails.locationList)) {
                const locationList = user.data.userDetails.locationList.filter(e => parseFloat(e.businessId) === parseFloat(selectedBusiness.businessId))
                if (!isEmpty(locationList)) {
                    setFilteredLocationList(locationList)
                } else {
                    setFilteredLocationList(null)
                }
            } else {
                setFilteredLocationList(null)
            }
        }
    }, [selectedBusiness])

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Work Location' />
                <CommonFormBorder>
                    <SubTextLabel label='Select your work location to continue' />
                    <FormFieldLabel label='Select Business' />
                    {!isEmpty(user.data.userDetails.businessList) && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedBusiness}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={user.data.userDetails.businessList}
                        placeholder='Select Business'
                        onChange={e => setSelectedBusiness(e)} />}
                    {isEmpty(user.data.userDetails.businessList) && <div className="content-error">No assigned business found</div>}
                    <FormFieldLabel label='Select Business Location' />
                    {filteredLocationList && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={filteredLocationList}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isEmpty(filteredLocationList) && <div className="content-error">No location found</div>}
                    <ButtonRow>
                        <CommonButton label='Continue'
                            onClick={() => { submitHandler() }} />
                    </ButtonRow>

                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeSelectLocation