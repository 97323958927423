import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import NavigationBar from "../../navigation-bar/navigationBar";
import NavBarComponent from "../nav-bar/navBar";
import ScreenFooter from "../screen-footer/screenFooter";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ROUTES } from "../../../utils/AppConstants";

const BusinessLandingPage = props => {
    const [isLoading, setLoading] = useState(false)
    let navigate = useNavigate();

    const data = [
        { img: require('../../../assets/img_serve_restaurant.jpg'), heading: 'Restaurants', content: "If your restaurant isn't operating at full capacity, you're missing out on potential profits. GoMint helps by posting attractive offers that draw new diners to your location, filling more of your existing seats quickly and profitably without increasing your operating costs" },
        { img: require('../../../assets/img_serve_fuel.jpg'), heading: 'Fuel & Convenience', content: "With customers having more power than ever to find the best prices, GoMint helps you attract new customers and grow your business by directing them from competitors to your station through targeted offers." },
        { img: require('../../../assets/img_serve_grocery.jpg'), heading: 'Grocery', content: 'Grocery stores often operate below full capacity. GoMint offers the fastest and most profitable way to attract new customers with compelling store offers, driving same-store sales growth without increasing costs.' },
        { img: require('../../../assets/img_serve_retail.jpg'), heading: 'Retail', content: "Customers often struggle to find the best offers from nearby retail stores, which can limit foot traffic. With GoMint, customers can easily discover top offers directly from retail stores, leading to increased sales growth with minimal effort." }
    ]

    const handleGetStartedClick = () => {
        navigate(ROUTES.vendorLogin)
    }

    return (
        <>
            {/* <NavigationBar navigate={navigate} /> */}
            <NavBarComponent navigate={navigate}
                showOfferList={false}
                showCustomerLogin={false}
                showForBusiness={false}
                showBusinessLogin={true}
                showEmployeeLogin={true}
                showBusinessRegistration={true}
                showForCustomer={true} />
            <div className="hero-container">
                <div className="hero-left-text-container">
                    <div className="content-text">
                        <h1 className="h-heading">A digital marketplace connecting you to the real world</h1>
                        <p className="h-content h-content-margin">GoMint brings customers straight to your store.</p>
                        <p className="h-content">Reach more customers. Increase sales. Grow your profits.</p>
                    </div>

                    <div className="get-started-btn"
                        onClick={() => { handleGetStartedClick() }} >Get Started</div>
                    <div className="hero-btn-subtext">For Business Owners</div>
                </div>
                <div className="hero-left-img-container">
                    <img src={require('../../../assets/hero_split.png')} className="img" />
                </div>
            </div>
            <div className="hp-section-two">
                <div className="hp-section-two-heading">Generate new in-store profits</div>
                <div className="hp-section-two-sub-heading">GoMint is the only platform that leverages your existing data and resources to generate measurable, incremental, and attributable in-store profit.</div>
                <div className="hp-section-two-row-container">
                    <div className="hp-section-two-img-parent-container hp-order-index">
                        <div className="hp-section-two-img-container">
                            <img src={require('../../../assets/img_attract_customer1.jpg')} className="img" />
                        </div>
                    </div>
                    <div className="hp-section-two-text-container">
                        <div className="hp-section-text-heading">Bring in new customers</div>
                        <div className="hp-section-text-content">Joining the GoMint platform extends your business's reach to more consumers through the GoMint app and our network of partner apps, right when they're choosing where to shop, dine, or refuel.</div>
                    </div>
                </div>
                <div className="hp-section-two-row-container">
                    <div className="hp-section-two-text-container">
                        <div className="hp-section-text-heading">Attract new customers and secure more transactions</div>
                        <div className="hp-section-text-content">GoMint delivers the perfect promotion at the right moment to influence consumer behavior and drive new transactions to your business.</div>
                    </div>
                    <div className="hp-section-two-img-parent-container hp-order-index">
                        <div className="hp-section-two-img-container">
                            <img src={require('../../../assets/img_win_customer.jpg')} className="img" />
                        </div>
                    </div>
                </div>
                <div className="hp-section-two-row-container">
                    <div className="hp-section-two-img-parent-container">
                        <div className="hp-section-two-img-container">
                            <img src={require('../../../assets/img_increment_profit.jpg')} className="img" />
                        </div>
                    </div>
                    <div className="hp-section-two-text-container">
                        <div className="hp-section-text-heading">Gain new, additional profits</div>
                        <div className="hp-section-text-content">GoMint promotions are crafted within your available margin, ensuring that you'll always earn more than you spend. We help fill your empty tables, checkout lanes, and gas pumps with new transactions, driving proven, incremental, and profitable sales.</div>
                    </div>
                </div>
                <div className="hp-section-two-row-container">
                    <div className="hp-section-two-text-container">
                        <div className="hp-section-text-heading">Track &amp; Analyze your return on investment</div>
                        <div className="hp-section-text-content">Log in to your personalized GoMint dashboard to view detailed program results, including incremental sales and profits generated for your business, broken down by customer type.</div>
                    </div>
                    <div className="hp-section-two-img-parent-container hp-order-index">
                        <div className="hp-section-two-img-container">
                            <img src={require('../../../assets/img_track_report.jpg')} className="img" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="hp-section-three">
                <div className="hp-section-three-heading">Who do we serve?</div>
                <div className="hp-feature-row">
                    {data.map(e => (<Card sx={{ maxWidth: 400, minHeight: 490, marginRight: 5, marginTop: 5 }}>
                        <CardMedia
                            sx={{ height: 240 }}
                            image={e.img}
                            title={e.heading}
                        />
                        <CardContent>
                            <div className="hp-serve-heading">{e.heading}</div>
                            <div className="hp-serve-content">{e.content}</div>
                            {/* <Typography gutterBottom variant="h4" component="div">
                                {e.heading}
                            </Typography> */}
                            {/* <Typography variant="body2" color="text.secondary">
                                {e.content}
                            </Typography> */}
                        </CardContent>
                    </Card>))}
                </div>
            </div>
            <ScreenFooter navigate={navigate}
                showForCustomer={true}
                showBusinessLogin={true}
                showBusinessRegistration={true} />
            {/* <header className="hero">
                <div class="content">
                    <div className="content-text">
                        <h1>A digital marketplace for the real world</h1>
                        <p>GoMint drives people into your store.</p>
                        <p>Reach new customers. Win more transactions. Grow profits.</p>
                    </div>

                    <div className="get-started-btn"
                        onClick={() => { handleGetStartedClick() }} >Get Started</div>
                </div>
            </header> */}
        </>
    )
}

export default BusinessLandingPage