import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    addEmployeeRequest, addEmployeeRequestStatus, employeeLoginCodeCheckRequest,
    clearEmployeeLoginCheck
} from '../../../redux/employee/employee.action'
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserVendor, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { userBusinessListRequest } from '../../../redux/business/business.action'
import { businessLocationListRequest } from '../../../redux/business-location/businessLocation.action'
import Select from 'react-select'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { PERMISSION_SUB_TYPE, PERMISSION_TYPE } from "../../../utils/PermissionConstants";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import Modal from 'react-bootstrap/Modal';

const AddEmployee = props => {
    const { user, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { addEmployeeSuccess, addEmployeeError, addEmployeeReqStatus,
        employeeLoginCodeCheckSuccess, employeeLoginCodeCheckError
    } = useSelector(state => state.employee)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    let navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState()
    const [email, setEmail] = useState('')
    const [employeeCode, setEmployeeCode] = useState('')
    const [employeeLoginCode, setEmployeeLoginCode] = useState('')
    const [employeeRole, setEmployeeRole] = useState('')

    const [isBusinessPermission, setIsBusinessPermission] = useState(false)
    const [addNewBusiness, setAddNewBusiness] = useState(false)
    const [editBusinessDetail, setEditBusinessDetail] = useState(false)

    const [isLocationPermission, setIsLocationPermission] = useState(false)
    const [addNewLocation, setAddNewLocation] = useState(false)
    const [editLocationDetail, setEditLocationDetail] = useState(false)

    const [isOfferPermission, setIsOfferPermission] = useState(false)
    const [addNewOfferPermission, setAddNewOfferPermission] = useState(false)
    const [editOfferPermission, setEditOfferPermission] = useState(false)

    const [rewardCashbackPermission, setRewardCashbackPermission] = useState(false)

    const [customerCashbackRewardPermission, setCustomerCashbackRewardPermission] = useState(false)

    const [isInvoicePermission, setIsInvoicePermission] = useState(false)
    const [viewInvoiceDetail, setViewInvoiceDetail] = useState(false)

    const [isEmployeePermission, setIsEmployeePermission] = useState(false)
    const [addEmployeePer, setAddEmployeePer] = useState(false)
    const [updateEmployeePer, setUpdateEmployeePer] = useState(false)
    const [removeEmployeePer, setRemoveEmployeePer] = useState(false)

    const [loginCodeEditCount, setLoginCodeEditCount] = useState(0)
    const [addEmployeeSuccessModel, setAddEmployeeSuccessModel] = useState(false)

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_EMPLOYEE, true)
            setLoading(true)
            const data1 = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data1))
        }
    }, [])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(employeeLoginCodeCheckSuccess)) {
            setLoading(false)
        } else if (!isNull(employeeLoginCodeCheckError)) {
            setLoading(false)
        }
    }, [employeeLoginCodeCheckSuccess, employeeLoginCodeCheckError])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.ADD_EMPLOYEE) === 'true') {
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isNull(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (isLoggedInUserVendor(user) === true && !isNull(businessLocationListSuccess)) {
            setLoading(false)
            setSelectedLocation(businessLocationListSuccess.data[0])
        } else if (!isNull(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (addEmployeeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            setAddEmployeeSuccessModel(true)
            // displaySuccessToast(addEmployeeSuccess.message)
            clearFields()
        } else if (addEmployeeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addEmployeeError.message)
        }

        if (addEmployeeReqStatus?.length) {
            dispatch(addEmployeeRequestStatus(''))
        }
    }, [addEmployeeSuccess, addEmployeeError, addEmployeeReqStatus])

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setEmployeeCode('')
        setEmployeeLoginCode('')
        setEmployeeRole('')
        setIsBusinessPermission(false)
        setAddNewBusiness(false)
        setEditBusinessDetail(false)
        setIsLocationPermission(false)
        setAddNewLocation(false)
        setEditLocationDetail(false)
        setIsOfferPermission(false)
        setAddNewOfferPermission(false)
        setEditOfferPermission(false)
        setRewardCashbackPermission(false)
        setCustomerCashbackRewardPermission(false)
        setIsInvoicePermission(false)
        setViewInvoiceDetail(false)
        setIsEmployeePermission(false)
        setAddEmployeePer(false)
        setUpdateEmployeePer(false)
        setRemoveEmployeePer(false)
        dispatch(clearEmployeeLoginCheck(''))
    }

    const submitHandler = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email) && isEmpty(mobile)) {
            displayErrorToast('Both mobile and email id cannot be blank')
        } else if (isEmpty(employeeRole)) {
            displayErrorToast('Employee role cannot be blank')
        } else if (isEmpty(employeeCode)) {
            displayErrorToast('Employee code cannot be blank')
        } else if (isEmpty(employeeLoginCode)) {
            displayErrorToast('Employee login code cannot be blank')
        } else if (isEmpty(employeeLoginCodeCheckSuccess)) {
            displayErrorToast('Please check employee login code availability')
        } else if (!isEmpty(employeeLoginCodeCheckSuccess.data) && employeeLoginCodeCheckSuccess.data.isLoginCodeAvailable === false) {
            displayErrorToast('Employee login code already in use. Please try with different login code.')
        } else if (isLoggedInUserVendor(user) === true && isNull(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isLoggedInUserVendor(user) === true && isEmpty(selectedLocation)) {
            displayErrorToast('Business location not selected')
        } else if (isLocationPermission === false && isOfferPermission === false
            && rewardCashbackPermission === false && customerCashbackRewardPermission === false && isInvoicePermission === false
            && isEmployeePermission === false) {
            displayErrorToast('No permission selected. Please select atleast 1 permission')
        } else {
            setLoading(true)
            const data = {
                firstName: firstName,
                lastName: lastName,
                businessId: isLoggedInUserVendor(user) === true ? [selectedBusiness.businessId] : [employeeSelectedBusiness],
                locationId: isLoggedInUserVendor(user) === true ? [selectedLocation.locationId] : [employeeSelectedLocation],
                productCode: user.data.userDetails.productCode,
                employeeCode: employeeCode,
                employeeLoginCode: employeeLoginCode,
                role: employeeRole
            }
            if (!isEmpty(mobile)) {
                const mobileNum = mobile.replace('+', "")
                data.mobile = Number(mobileNum)
            }
            if (!isEmpty(email)) {
                data.email = email
            }
            const empPermissions = []
            if (isBusinessPermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.BUSINESS,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                obj[PERMISSION_SUB_TYPE.BUSINESS.ADD_NEW_BUSINESS] = addNewBusiness
                obj[PERMISSION_SUB_TYPE.BUSINESS.EDIT_BUSINESS_DETAIL] = editBusinessDetail
                empPermissions.push(obj)
            }
            if (isLocationPermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.LOCATION,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                obj[PERMISSION_SUB_TYPE.LOCATION.ADD_NEW_LOCATION] = addNewLocation
                obj[PERMISSION_SUB_TYPE.LOCATION.EDIT_LOCATION_DETAIL] = editLocationDetail
                empPermissions.push(obj)
            }
            if (isOfferPermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.OFFERS,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                obj[PERMISSION_SUB_TYPE.OFFERS.ADD_NEW_OFFER] = addNewOfferPermission
                obj[PERMISSION_SUB_TYPE.OFFERS.EDIT_OFFER_DETAIL] = editOfferPermission
                empPermissions.push(obj)
            }
            if (rewardCashbackPermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.REWARD_CASHBACK,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                empPermissions.push(obj)
            }
            if (customerCashbackRewardPermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.CUSTOMER_CASHBACK_REWARD,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                empPermissions.push(obj)
            }
            if (isInvoicePermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.INVOICE,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                obj[PERMISSION_SUB_TYPE.INVOICE.VIEW_INVOICE_DETAIL] = viewInvoiceDetail
                empPermissions.push(obj)
            }
            if (isEmployeePermission === true) {
                let obj = {
                    type: PERMISSION_TYPE.EMPLOYEE,
                    businessId: isLoggedInUserVendor(user) === true ? selectedBusiness.businessId : employeeSelectedBusiness,
                    locationId: isLoggedInUserVendor(user) === true ? selectedLocation.locationId : employeeSelectedLocation
                }
                obj[PERMISSION_SUB_TYPE.EMPLOYEE.ADD_NEW_EMPLOYEE] = addEmployeePer
                obj[PERMISSION_SUB_TYPE.EMPLOYEE.REMOVE_EMPLOYEE] = removeEmployeePer
                obj[PERMISSION_SUB_TYPE.EMPLOYEE.UPDATE_DETAILS] = updateEmployeePer
                empPermissions.push(obj)
            }
            data.permissions = empPermissions
            dispatch(addEmployeeRequest(data))
        }
    }

    useEffect(() => {
        if (isBusinessPermission === true) {
            setAddNewBusiness(true)
            setEditBusinessDetail(true)
        } else {
            setAddNewBusiness(false)
            setEditBusinessDetail(false)
        }
    }, [isBusinessPermission])

    useEffect(() => {
        if (isLocationPermission === true) {
            setEditLocationDetail(true)
            setAddNewLocation(true)
        } else {
            setEditLocationDetail(false)
            setAddNewLocation(false)
        }
    }, [isLocationPermission])

    useEffect(() => {
        if (isEmployeePermission === true) {
            setAddEmployeePer(true)
            setRemoveEmployeePer(true)
            setUpdateEmployeePer(true)
        } else {
            setAddEmployeePer(false)
            setRemoveEmployeePer(false)
            setUpdateEmployeePer(false)
        }
    }, [isEmployeePermission])

    useEffect(() => {
        if (isOfferPermission === true) {
            setAddNewOfferPermission(true)
            setEditOfferPermission(true)
        } else {
            setAddNewOfferPermission(false)
            setEditOfferPermission(false)
        }
    }, [isOfferPermission])

    useEffect(() => {
        if (isInvoicePermission === true) {
            setViewInvoiceDetail(true)
        } else {
            setViewInvoiceDetail(false)
        }
    }, [isInvoicePermission])

    const handleEmployeeLoginCodeChange = (e) => {
        const value = e.target.value
        if (employeeLoginCode !== value) {
            setLoginCodeEditCount((prev) => prev + 1)
        }
        setEmployeeLoginCode(value)
        if (loginCodeEditCount >= 1) {
            dispatch(clearEmployeeLoginCheck(''))
        }
    }

    const handleLoginCodeVerifyCheckClick = () => {
        if (isEmpty(employeeLoginCode)) {
            displayErrorToast('Emplloyee login code cannot be blank')
        } else {
            setLoading(true)
            const data = {
                employeeLoginCode: employeeLoginCode
            }
            dispatch(employeeLoginCodeCheckRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Employee' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new employee' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            placeholder="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            isRequired={true}
                        />
                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            isRequired={true}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <div className="aemp-input-half">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile} />
                        </div>

                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            isRequired={true}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type="name"
                            id="role"
                            placeholder="Employee Role"
                            value={employeeRole}
                            onChange={e => setEmployeeRole(e.target.value)}
                        />
                        <CommonInputFloatHalf
                            type="name"
                            id="code"
                            placeholder="Employee Code"
                            value={employeeCode}
                            onChange={e => setEmployeeCode(e.target.value)}
                        />
                    </CommonInputRow>
                    <div className="ae-input-row-container">
                        <div className="ae-row-input-container">
                            <CommonInputFloatFull
                                type='text'
                                id='loginCode'
                                onChange={e => handleEmployeeLoginCodeChange(e)}
                                placeholder='Employee Login Code'
                                value={employeeLoginCode}
                                subText='Code to login on GoMint console'
                                isSubtext={true}
                            />
                        </div>
                        {!isEmpty(employeeLoginCodeCheckSuccess) && employeeLoginCodeCheckSuccess.data.isLoginCodeAvailable === true && <div className="ae-row-img-container">
                            <img src={require('../../../assets/ic_done_icon.png')} className="img" />
                        </div>}
                        <div className="ae-row-btn-container" onClick={() => { handleLoginCodeVerifyCheckClick() }}>Verify</div>
                    </div>
                    {isLoggedInUserVendor(user) === true && <div className="aemp-select-heading">Select Business</div>}
                    {isLoggedInUserVendor(user) === true && !isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedBusiness}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={userBusinessListSuccess.data}
                        placeholder='Select Business'
                        onChange={e => setSelectedBusiness(e)} />}
                    {isLoggedInUserVendor(user) === true && !isEmpty(userBusinessListError) && <div className="aemp-text">{userBusinessListError.message}</div>}
                    {isLoggedInUserVendor(user) === true && !isEmpty(selectedBusiness) && <div className="aemp-select-heading mt-2">Select Location</div>}
                    {isLoggedInUserVendor(user) === true && !isEmpty(selectedBusiness) && !isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={businessLocationListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {isLoggedInUserVendor(user) === true && !isEmpty(selectedBusiness) && !isEmpty(businessLocationListError) && <div className="aemp-text">{businessLocationListError.message}</div>}
                    <div className="aemp-select-heading mt-2">Select Employee Permissions</div>
                    {/* <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isBusinessPermission}
                            value={isBusinessPermission}
                            onChange={() => {
                                setIsBusinessPermission(!isBusinessPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Businesses</label>
                    </div> */}
                    {isBusinessPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={editBusinessDetail}
                                    value={editBusinessDetail}
                                    onChange={() => {
                                        setEditBusinessDetail(!editBusinessDetail)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Edit Details</label>
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addNewBusiness}
                                    value={addNewBusiness}
                                    onChange={() => {
                                        setAddNewBusiness(!addNewBusiness)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add New</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isLocationPermission}
                            value={isLocationPermission}
                            onChange={() => {
                                setIsLocationPermission(!isLocationPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Locations</label>
                    </div>
                    {isLocationPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={editLocationDetail}
                                    value={editLocationDetail}
                                    onChange={() => {
                                        setEditLocationDetail(!editLocationDetail)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Edit Details</label>
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addNewLocation}
                                    value={addNewLocation}
                                    onChange={() => {
                                        setAddNewLocation(!addNewLocation)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add New</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isOfferPermission}
                            value={isOfferPermission}
                            onChange={() => {
                                setIsOfferPermission(!isOfferPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Offers</label>
                    </div>
                    {isOfferPermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addNewOfferPermission}
                                    value={addNewOfferPermission}
                                    onChange={() => {
                                        setAddNewOfferPermission(!addNewOfferPermission)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add Offer</label>
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={editOfferPermission}
                                    value={editOfferPermission}
                                    onChange={() => {
                                        setEditOfferPermission(!editOfferPermission)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Edit Offer</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={rewardCashbackPermission}
                            value={rewardCashbackPermission}
                            onChange={() => {
                                setRewardCashbackPermission(!rewardCashbackPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Reward Cashback</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={customerCashbackRewardPermission}
                            value={customerCashbackRewardPermission}
                            onChange={() => {
                                setCustomerCashbackRewardPermission(!customerCashbackRewardPermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Cashback Rewards</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isInvoicePermission}
                            value={isInvoicePermission}
                            onChange={() => {
                                setIsInvoicePermission(!isInvoicePermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Invoices</label>
                    </div>
                    {isInvoicePermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-12">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={viewInvoiceDetail}
                                    value={viewInvoiceDetail}
                                    onChange={() => {
                                        setViewInvoiceDetail(!viewInvoiceDetail)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">View Details</label>
                            </div>
                        </div>
                    </div>}
                    <div>
                        <input
                            type="checkbox"
                            className="form-check-input input-text"
                            id="name"
                            checked={isEmployeePermission}
                            value={isEmployeePermission}
                            onChange={() => {
                                setIsEmployeePermission(!isEmployeePermission)
                            }}
                            required />
                        <label for="name" className="aemp-checkbox-label">Employees</label>
                    </div>
                    {isEmployeePermission === true && <div className="container mt-1 mb-1">
                        <div className="row">
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={addEmployeePer}
                                    value={addEmployeePer}
                                    onChange={() => {
                                        setAddEmployeePer(!addEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Add Employee</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={removeEmployeePer}
                                    value={removeEmployeePer}
                                    onChange={() => {
                                        setRemoveEmployeePer(!removeEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Remove Employee</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-text"
                                    id="name"
                                    checked={updateEmployeePer}
                                    value={updateEmployeePer}
                                    onChange={() => {
                                        setUpdateEmployeePer(!updateEmployeePer)
                                    }}
                                    required />
                                <label for="name" className="aemp-checkbox-label aemp-checkbox-label-black">Update Details</label>
                            </div>
                        </div>
                    </div>}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => { submitHandler() }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <Modal
                onHide={() => { setAddEmployeeSuccessModel(false) }}
                show={addEmployeeSuccessModel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(addEmployeeSuccess) && <div className="ae-model">
                        <div className="ae-model-img-container">
                            <img src={require('../../../assets/ic_success_img.png')} className="img" />
                        </div>
                        <div className="ae-model-message">{addEmployeeSuccess?.data?.message}</div>
                        <div className="ae-model-sub-message">Temporary Password to login on Gomint</div>
                        <div className="ae-model-text">{addEmployeeSuccess?.data?.tempPassword}</div>
                    </div>}
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddEmployee