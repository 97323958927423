import React, { useEffect, useState } from "react";
import './styles.css'
import { useLocation, useNavigate } from 'react-router-dom';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { displayCurrencySymbolLeft, displayErrorToast, isEmpty, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import { vendorCashbackDetailRequest } from '../../../redux/cashback/cashback.action'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import moment from "moment";
import CommonButton from "../../../components/button/common-button/commonButton";
import { ROUTES } from "../../../utils/AppConstants";

const VendorCustomerCashbackDetail = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const { vendorCashbackDetailSuccess, vendorCashbackDetailError } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        fetchCashbackDetail()
    }, [])

    const fetchCashbackDetail = () => {
        if (!isEmpty(state)) {
            setLoading(true)
            dispatch(vendorCashbackDetailRequest(state))
        }
    }

    useEffect(() => {
        if (!isEmpty(vendorCashbackDetailSuccess)) {
            setLoading(false)
        } else if (!isEmpty(vendorCashbackDetailError)) {
            setLoading(false)
            displayErrorToast(vendorCashbackDetailError.message)
        }
    }, [vendorCashbackDetailSuccess, vendorCashbackDetailError])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleCreateInvoice = () => {
        navigate(ROUTES.createInvoice, { state: state })
    }

    const handleViewInvoiceDetail = (element) => {
        navigate(ROUTES.invoiceDetail, { state: element.invoiceId })
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Vendor Cashback Details' />
                {!isEmpty(vendorCashbackDetailSuccess) && !isEmpty(vendorCashbackDetailSuccess.data) && <CommonFormBorder>
                    {parseFloat(vendorCashbackDetailSuccess.data.totalCashbackAmount) > 0 && <div className="vccd-invoice-button-container">
                        <div className="vccd-invoice-button" onClick={() => { handleCreateInvoice() }}>Create Invoice</div>
                    </div>}
                    <div className="vccd-business-label">{vendorCashbackDetailSuccess.data.businessDetails.businessName}</div>
                    <div className="vccd-address-label">{vendorCashbackDetailSuccess.data.locationDetails.address}</div>
                    <div className="vccd-address-label">{vendorCashbackDetailSuccess.data.locationDetails.city}, {vendorCashbackDetailSuccess.data.locationDetails.state}, {vendorCashbackDetailSuccess.data.locationDetails.country}</div>
                    <div className="vccd-reward-container">
                        <div className="vccd-reward-label-container">Total Cashback Rewarded</div>
                        <div className="vccd-reward-label-container">{getAmountWithCurrency(vendorCashbackDetailSuccess.data, vendorCashbackDetailSuccess.data.totalCashbackAmount)}</div>
                    </div>
                    {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div>
                        <div className='form-field-label-grey'>Cashback Details</div>
                    </div>}
                    {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div className="table-scroll-container">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Offer</th>
                                    {/* <th scope="col" className="rl-table-col-title text-center">Offer Date</th> */}
                                    {/* <th scope="col" className="rl-table-col-title text-center">Brand Name</th> */}
                                    <th scope="col" className="rl-table-col-title text-center">Customer Number</th>
                                    <th scope="col" className="rl-table-col-title text-center">Cashback Date</th>
                                    <th scope="col" className="rl-table-col-title text-center">Cashback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vendorCashbackDetailSuccess.data.cashbackLog.map(item => (
                                    <tr className="rl-table-row-container">
                                        <td className="rl-table-col-item">
                                            <div className="vccd-cl-brand-label">{item.brand}</div>
                                            <div className="vccd-cl-offer-label">{item.offerName}</div>
                                            <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                                        </td>
                                        {/* <td className="rl-table-col-item text-center">{moment(item.startDate).utc().format('Do MMM YY')} - {moment(item.endDate).utc().format('Do MMM YY')}</td> */}
                                        {/* <td className="rl-table-col-item text-center">{item.brand}</td> */}
                                        <td className="rl-table-col-item text-center align-middle">+{item.customerMobile}</td>
                                        <td className="rl-table-col-item text-center align-middle">{moment(item.createdOn).utc().format('Do MMM YY')}</td>
                                        <td className="rl-table-col-item text-center align-middle">{getAmountWithCurrency(vendorCashbackDetailSuccess.data, item.casbackAmount)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div className="vccd-log-parent-container">
                        <FormFieldLabel className='up-bold-label' label='Cashback Details' />
                        {vendorCashbackDetailSuccess.data.cashbackLog.map(item => (<div className="vccd-log-row-container">
                            <div className="vccd-cl-brand-label">{item.brand}</div>
                            <div className="vccd-cl-offer-label">{item.offerName}</div>
                            <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                            <div className="vccd-cl-customer-mobile-label">Customer Number: +{item.customerMobile}</div>
                            <div className="vccd-cl-cashback-reward-amount">Cashback Rewarded: {getAmountWithCurrency(vendorCashbackDetailSuccess.data, item.casbackAmount)}</div>
                        </div>))}
                    </div>} */}
                    {!isEmpty(vendorCashbackDetailSuccess.data.invoiceLogDetails) && <div className="vccd-log-parent-container">
                        <FormFieldLabel className='up-bold-label' label='Previous Invoice Details' />
                        <div className="table-scroll-container">
                            {!isNull(vendorCashbackDetailSuccess.data.invoiceLogDetails) && <table class="table table-hover margin-top-10">
                                <thead>
                                    <tr>
                                        <th scope="col" className="rl-table-col-title">Invoice Number</th>
                                        <th scope="col" className="rl-table-col-title text-center">Amount</th>
                                        <th scope="col" className="rl-table-col-title text-center">Payment Mode</th>
                                        <th scope="col" className="rl-table-col-title text-center">Payment Status</th>
                                        <th scope="col" className="rl-table-col-title text-center">Date</th>
                                        <th scope="col" className="rl-table-col-title text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorCashbackDetailSuccess.data.invoiceLogDetails.map(element => (
                                        <tr className="rl-table-row-container">
                                            <td className="rl-table-col-item">{element.invoiceId}</td>
                                            <td className="rl-table-col-item text-center">{getAmountWithCurrency(element, parseFloat(element.finalAmount).toFixed(2))}</td>
                                            <td className="rl-table-col-item text-center">{element.paymentMode}</td>
                                            <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.paymentStatus)}</td>
                                            <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                            <td className="rl-table-col-item text-center" onClick={() => {
                                                handleViewInvoiceDetail(element)
                                            }}>
                                                <div className="tr-action-btn">View Details</div></td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>}
                        </div>
                    </div>}

                </CommonFormBorder>}

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VendorCustomerCashbackDetail