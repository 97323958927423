import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { displayCurrencySymbolLeft, isEmpty, isLoggedInUserOperator, isNull } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { vendorCashbackListRequest } from '../../../redux/cashback/cashback.action'
import { ROUTES } from "../../../utils/AppConstants";

const VendorCustomerCashbackList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { vendorCashbackListSuccess, vendorCashbackListError } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();

    useEffect(() => {
        if (isLoggedInUserOperator(user)) {
            if (!isEmpty(user.data.userDetails.accessLocations)) {
                setCountryDropdown(user.data.userDetails.accessLocations[0])
            }
        }
    }, [user])

    const fetchCashackRewardList = () => {
        if (!isEmpty(countryDropdown)) {
            setLoading(true)
            const data = {
                country: countryDropdown.country
            }
            dispatch(vendorCashbackListRequest(data))
        }
    }

    useEffect(() => {
        if (!isEmpty(vendorCashbackListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(vendorCashbackListError)) {
            setLoading(false)
        }
    }, [vendorCashbackListSuccess, vendorCashbackListError])

    useEffect(() => {
        if (!isEmpty(countryDropdown)) {
            fetchCashackRewardList()
        }
    }, [countryDropdown])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleCardClick = item => {
        navigate(ROUTES.vendorCashbackRewardsDetails, { state: item.businessCashbackBookId })
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Vendor Cashback' />
                <div>
                    <FormFieldLabel className='up-bold-label' label='Select Location' />
                    {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                    {!isEmpty(user.data.userDetails.accessLocations) &&
                        <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.country}
                            getOptionValue={e => e}
                            options={user.data.userDetails.accessLocations}
                            placeholder='Select Country'
                            onChange={e => {
                                setCountryDropdown(e)
                            }} />
                    }
                </div>
                {!isNull(vendorCashbackListSuccess) && <div className="table-scroll-container">
                    {!isNull(vendorCashbackListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Merchant/Business Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Location</th>
                                <th scope="col" className="rl-table-col-title text-center">Address</th>
                                <th scope="col" className="rl-table-col-title text-center">Total Cashback Rewarded</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendorCashbackListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.business.businessName}</td>
                                    <td className="rl-table-col-item text-center">{element.location.address}</td>
                                    <td className="rl-table-col-item text-center">{element.location.city}, {element.location.state}, {element.location.country}</td>
                                    <td className="rl-table-col-item text-center">{getAmountWithCurrency(element, parseFloat(element.totalCashbackAmount).toFixed(2))}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => {
                                            handleCardClick(element)
                                        }}><div className="tr-action-btn">View Details</div></div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isEmpty(countryDropdown) && !isEmpty(vendorCashbackListError) && <div className="content-error">{vendorCashbackListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VendorCustomerCashbackList