import React, { useEffect, useState } from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import NavBarComponent from "../nav-bar/navBar";
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import ScreenFooter from "../screen-footer/screenFooter";
import { getHeading2Content, getHeading3Content, getHeadingContent, getViewOffersContent } from "./landingPageContent";
import { useSelector, useDispatch } from 'react-redux';
import { clearLocalStorage, isEmpty } from "../../../utils/Utils";

const LandingPage = props => {
    let navigate = useNavigate();
    const { selectedLocation } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        clearLocalStorage()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    const handleViewOffersClick = () => {
        navigate(ROUTES.offerList)
    }

    return (
        <>
            <NavBarComponent
                navigate={navigate}
                showOfferList={true}
                showCustomerLogin={true}
                showForBusiness={true}
                isWhiteBackground={true}
            />
            <div className="lp-hero-container">
                <div className="hero-left-text-container">
                    <div className="content-text">
                        <h1 className="h-heading">{getHeadingContent(!isEmpty(selectedLocation) ? selectedLocation.key : APP_CONSTANTS.DEFAULT_LOCATION_KEY)}</h1>
                        <p className="h-content h-content-margin">{getHeading2Content(!isEmpty(selectedLocation) ? selectedLocation.key : APP_CONSTANTS.DEFAULT_LOCATION_KEY)}</p>
                        <p className="h-content">{getHeading3Content(!isEmpty(selectedLocation) ? selectedLocation.key : APP_CONSTANTS.DEFAULT_LOCATION_KEY)}</p>
                    </div>

                    <div className="get-started-btn"
                        onClick={() => { handleViewOffersClick() }} >{getViewOffersContent(!isEmpty(selectedLocation) ? selectedLocation.key : APP_CONSTANTS.DEFAULT_LOCATION_KEY)}</div>
                </div>
                <div className="hero-left-img-container">
                    <img src={require('../../../assets/hero_customer_landing.jpg')} className="img" />
                </div>
            </div>
            <div className="hp-section-two">
                <div className="hp-section-two-heading">How GoMint Works</div>
                <div className="lp-customer-step-row-container">
                    <div className="lp-customer-step-row-item">
                        <div className="lp-customer-row-item-img">
                            <img src={require('../../../assets/customer_step_1.png')} className="img" />
                        </div>
                        <div className="lp-customer-row-label">Explore Offers</div>
                        <div className="lp-customer-row-sub-text">Browse available offers on GoMint, and filter them by zipcode or location to find the best deals.</div>
                    </div>
                    <div className="lp-customer-step-row-item">
                        <div className="lp-customer-row-item-img">
                            <img src={require('../../../assets/customer_step_2.png')} className="img" />
                        </div>
                        <div className="lp-customer-row-label">Shop and Save</div>
                        <div className="lp-customer-row-sub-text">Visit the vendor or merchant who posted the offer and complete your purchase.</div>
                    </div>
                    <div className="lp-customer-step-row-item">
                        <div className="lp-customer-row-item-img">
                            <img src={require('../../../assets/customer_step_3.png')} className="img" />
                        </div>
                        <div className="lp-customer-row-label">Redeem Your Cashback</div>
                        <div className="lp-customer-row-sub-text">Redeem the cashback you’ve earned and watch your savings grow!</div>
                    </div>
                </div>
            </div>
            <ScreenFooter navigate={navigate}
                showForBusiness={true}
                showCustomerLogin={true} />
        </>
    )
}

export default LandingPage