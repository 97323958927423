import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isLoggedInUserVendor, isNull, screenCaptureAnalytics } from "../../../../utils/Utils";
import { countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import { userBusinessListRequest } from '../../../../redux/business/business.action'
import { addBusinessLocationRequest, addBusinessLocationRequestStatus } from '../../../../redux/business-location/businessLocation.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import Select from 'react-select'
import CommonButton from "../../../../components/button/common-button/commonButton";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputFloatFull from "../../../../components/input/common-input-float-full/commonInputFloatFull";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../components/input/common-input-float-half/commonInputFloafHalf";

const AddLocationScreen = props => {
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, employeeSelectedBusiness, employeeSelectedLocation } = useSelector(state => state.user)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { addBusinessLocationSuccess, addBusinessLocationError, addBusinessLocationReqStatus } = useSelector(state => state.businessLocation)
    const [taxType, setTaxType] = useState('')
    const [taxNumber, setTaxNumber] = useState('')

    useEffect(() => {
        const data = {
            screen: 'ADD_LOCATION',
            screenClass: 'ADD_LOCATION'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        setLoading(true)
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        dispatch(countryListRequest())
        if (isLoggedInUserVendor(user) === true) {
            sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_LOCATION, true)
            const data = {
                userId: user.data.userDetails.userId
            }
            dispatch(userBusinessListRequest(data))
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
            if (sessionStorage.getItem(APP_CONSTANTS.SCREEN.ADD_LOCATION) === 'true') {
                sessionStorage.setItem(APP_CONSTANTS.SCREEN.ADD_LOCATION, false)
                setSelectedBusiness(userBusinessListSuccess.data[0])
            }
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(countryListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isEmpty(stateListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isEmpty(cityListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (addBusinessLocationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addBusinessLocationSuccess.message)
            clearFields()
        } else if (addBusinessLocationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addBusinessLocationError.message)
        }

        if (addBusinessLocationReqStatus?.length) {
            dispatch(addBusinessLocationRequestStatus(''))
        }
    }, [addBusinessLocationSuccess, addBusinessLocationError, addBusinessLocationReqStatus])

    const clearFields = () => {
        setName('')
        setAddress('')
        setTaxType('')
        setTaxNumber('')
    }

    const submitHandler = () => {
        if (isEmpty(name)) {
            displayErrorToast('Location name cannot be blank')
        } else if (isLoggedInUserVendor(user) && isNull(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isEmpty(address)) {
            displayErrorToast('Location address cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Location country is not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Location state is not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Location state is not selected')
        } else {
            setLoading(true)
            const data = {
                name: name,
                businessId: isLoggedInUserVendor(user) ? selectedBusiness.businessId : employeeSelectedBusiness,
                productCode: user.data.userDetails.productCode,
                country: countryDropdown.name,
                state: stateDropdown.name,
                city: cityDropdown.name,
                countryCode: countryDropdown.iso2,
                stateCode: stateDropdown.iso2,
                address: address
            }
            if (!isEmpty(taxType)) {
                data.taxType = taxType
            }
            if (!isEmpty(taxNumber)) {
                data.taxNumber = taxNumber
            }
            dispatch(addBusinessLocationRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2">
                    <ScreenLabel label='Add Location' />
                </div>
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new business location' />
                    <CommonInputFloatFull
                        type="name"
                        id="name"
                        placeholder="Location Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        isRequired={true}
                    />
                    {isLoggedInUserVendor(user) && !isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                        className='select-full-width-no-border'
                        value={selectedBusiness}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={userBusinessListSuccess.data}
                        placeholder='Select Business'
                        onChange={e => setSelectedBusiness(e)} />}
                    {isLoggedInUserVendor(user) && !isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                    <FormFieldLabel label='Address Information' />
                    <CommonInputFloatFull
                        type="name"
                        id="address"
                        placeholder="Location Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        isRequired={true}
                    />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e);
                            setCountryCode(e.iso2)
                            fetchStateList(e.iso2)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => submitHandler()} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddLocationScreen