import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
import { addInvoiceRequest, addInvoiceRequestStatus } from '../../../redux/invoice/invoice.action'
import { operatorDetailRequest } from '../../../redux/user/user.action'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isNull } from "../../../utils/Utils";
import { useNavigate, useLocation } from 'react-router-dom';
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { Checkbox } from "@mui/material";
import { vendorCashbackDetailRequest } from '../../../redux/cashback/cashback.action'

const CreateInvoice = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, operatorDetailSuccess, operatorDetailError } = useSelector(state => state.user)
    const { addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus } = useSelector((state) => state.invoice)
    const { vendorCashbackDetailSuccess, vendorCashbackDetailError } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { state } = useLocation()

    useEffect(() => {
        fetchCashbackDetail()
        fetchOperatorDetails()
    }, [])

    const fetchCashbackDetail = () => {
        if (!isEmpty(state)) {
            setLoading(true)
            dispatch(vendorCashbackDetailRequest(state))
        }
    }

    const fetchOperatorDetails = () => {
        if (!isEmpty(user) &&
            !isEmpty(user.data) &&
            !isEmpty(user.data.userDetails)) {
            setLoading(true)
            dispatch(operatorDetailRequest(user.data.userDetails.userId))
        }
    }

    useEffect(() => {
        if (addInvoiceReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvoiceSuccess.data.message)
            setTimeout(() => {
                navigate(-1)
            }, 800)
        } else if (addInvoiceReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvoiceError.message)
        }
        if (addInvoiceReqStatus.length) {
            dispatch(addInvoiceRequestStatus(''))
        }
    }, [addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus])

    useEffect(() => {
        if (!isEmpty(vendorCashbackDetailSuccess)) {
            setLoading(false)
        } else if (!isEmpty(vendorCashbackDetailError)) {
            setLoading(false)
            displayErrorToast(vendorCashbackDetailError.message)
        }
    }, [vendorCashbackDetailSuccess, vendorCashbackDetailError])

    useEffect(() => {
        if (!isNull(operatorDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(operatorDetailError)) {
            setLoading(false)
            displayErrorToast(operatorDetailError.message)
        }
    }, [operatorDetailSuccess, operatorDetailError])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleCreateInvoiceClick = () => {
        if (isEmpty(operatorDetailSuccess)) {
            displayErrorToast('Operator information not available. Please try again')
        } else if (!isLoggedInUserOperator(user)) {
            displayErrorToast('Only operator is allowed to create invoice')
        } else if (isEmpty(vendorCashbackDetailSuccess)) {
            displayErrorToast('Vendor cashback reward information not available. Please try again')
        } else {
            const data = {
                businessId: vendorCashbackDetailSuccess.data.businessId,
                locationId: vendorCashbackDetailSuccess.data.locationId,
                operatorId: operatorDetailSuccess.data.userId,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                businessCashbackBookId: state
            }
            setLoading(true)
            dispatch(addInvoiceRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Create New Invoice' />
                <CommonFormBorder>
                    <div className="ci-logo-parent-container">
                        <div className="ci-logo-container">
                            <img src={require('../../../assets/logo_gomint2.png')} className="img" />
                        </div>
                    </div>
                    <div className="vccd-address-label mb-2">{moment(new Date()).format('DD MMM YYYY')}</div>
                    {!isEmpty(vendorCashbackDetailSuccess) && !isEmpty(vendorCashbackDetailSuccess.data) && <div>
                        <div className="vccd-business-label">{vendorCashbackDetailSuccess.data.businessDetails.businessName}</div>
                        <div className="vccd-address-label">{vendorCashbackDetailSuccess.data.locationDetails.address}</div>
                        <div className="vccd-address-label">{vendorCashbackDetailSuccess.data.locationDetails.city}, {vendorCashbackDetailSuccess.data.locationDetails.state}, {vendorCashbackDetailSuccess.data.locationDetails.country}</div>

                    </div>}
                    {!isEmpty(operatorDetailSuccess) && !isEmpty(operatorDetailSuccess.data) && <div>
                        <div className="ci-data-label mt-4">Operator Details</div>
                        <div className="ci-business-label">{operatorDetailSuccess.data.firstName} {operatorDetailSuccess.data.lastName}</div>
                        <div className="ci-address-label">{operatorDetailSuccess.data.email}</div>
                        <div className="ci-address-label">+{operatorDetailSuccess.data.mobile}</div>

                    </div>}
                    {!isEmpty(vendorCashbackDetailSuccess) && !isEmpty(vendorCashbackDetailSuccess.data) && <div>
                        <div className="vccd-reward-container">
                            <div className="vccd-reward-label-container">Payable Amount</div>
                            <div className="vccd-reward-label-container">{getAmountWithCurrency(vendorCashbackDetailSuccess.data, vendorCashbackDetailSuccess.data.totalCashbackAmount)}</div>
                        </div>
                    </div>}
                    {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div>
                        <div className='form-field-label-grey'>Cashback Details</div>
                    </div>}
                    {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div className="table-scroll-container">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className="rl-table-col-title">Offer</th>
                                    {/* <th scope="col" className="rl-table-col-title text-center">Offer Date</th> */}
                                    {/* <th scope="col" className="rl-table-col-title text-center">Brand Name</th> */}
                                    <th scope="col" className="rl-table-col-title text-center">Customer Number</th>
                                    <th scope="col" className="rl-table-col-title text-center">Cashback Date</th>
                                    <th scope="col" className="rl-table-col-title text-center">Cashback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vendorCashbackDetailSuccess.data.cashbackLog.map(item => (
                                    <tr className="rl-table-row-container">
                                        <td className="rl-table-col-item">
                                            <div className="vccd-cl-brand-label">{item.brand}</div>
                                            <div className="vccd-cl-offer-label">{item.offerName}</div>
                                            <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                                        </td>
                                        {/* <td className="rl-table-col-item text-center">{moment(item.startDate).utc().format('Do MMM YY')} - {moment(item.endDate).utc().format('Do MMM YY')}</td> */}
                                        {/* <td className="rl-table-col-item text-center">{item.brand}</td> */}
                                        <td className="rl-table-col-item text-center align-middle">+{item.customerMobile}</td>
                                        <td className="rl-table-col-item text-center align-middle">{moment(item.createdOn).utc().format('Do MMM YY')}</td>
                                        <td className="rl-table-col-item text-center align-middle">{getAmountWithCurrency(vendorCashbackDetailSuccess.data, item.casbackAmount)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* {!isEmpty(vendorCashbackDetailSuccess.data.cashbackLog) && <div className="vccd-log-parent-container">
                        <FormFieldLabel className='up-bold-label' label='Cashback Details' />
                        {vendorCashbackDetailSuccess.data.cashbackLog.map(item => (<div className="vccd-log-row-container">
                            <div className="vccd-cl-brand-label">{item.brand}</div>
                            <div className="vccd-cl-offer-label">{item.offerName}</div>
                            <div className="vccd-cl-validity-label">Offer valid from {moment(item.startDate).utc().format('Do MMM YY')} till {moment(item.endDate).utc().format('Do MMM YY')}</div>
                            <div className="vccd-cl-customer-mobile-label">Customer Number: +{item.customerMobile}</div>
                            <div className="vccd-cl-cashback-reward-amount">Cashback Rewarded: {getAmountWithCurrency(vendorCashbackDetailSuccess.data, item.casbackAmount)}</div>
                        </div>))}
                    </div>} */}
                    <ButtonRow>
                        <CommonButton label='Send Invoice' onClick={() => {
                            handleCreateInvoiceClick()
                        }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CreateInvoice